.main {
	display: flex;
	padding: 30px;
	justify-content: space-around;
}

.noReportMsg {
	display: flex;
	align-items: center;
	margin: 15px 0px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	justify-content: center;
	height: 100px;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	width: 100%;
}

.upgradeBtn {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	width: 200px !important;
	height: 40px !important;
	background-color: #3361ff !important;
	font-size: 12px !important;
	font-weight: bold !important;
}
