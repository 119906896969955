@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.main {
	display: flex;
	height: 100%;
	padding-bottom: 30px;
	.container {
		flex-grow: 1;
	}
}

.teamsPopupTitle {
	padding: 15px;
	text-align: center;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		width: calc(100% - 40px);
		height: 1px;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		background-color: #fff;
	}
}

.teamsPopupTitleBtn {
	padding: 5px;
	@include centerFlex;
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);

	svg {
		width: 15px;
		height: 15px;
		fill: #fff;
		transition: all 0.15s;
	}

	&:hover {
		svg {
			fill: $secondary;
		}
	}
}

.teamsPopupTitleClose {
	padding: 15px 50px;
}

.teamsPopupContent {
	padding: 25px 25px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 60px 120px #26334d1a;
	border-radius: 10px !important;
	opacity: 1;
	display: grid;
	@media (max-width: 500px) {
		padding: 15px;
	}
}

.bodyPopup {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	@include customScroll;
	padding: 0px 10px 0px 10px;
}

.teamsPopupText {
	font-size: 14px;
	line-height: 20px;
}

.teamsPopupBtns {
	margin-top: 0px;
	display: flex;
	align-items: center;
	position: relative;
	float: right;
}

.teamsPopupBtn {
	width: 130px;
	height: 40px;
	letter-spacing: 0.3px;
	font-weight: bold;
	box-shadow: 0px 9px 9px rgb(51 97 255 / 5%);

	border-radius: 53px !important;
	&:hover {
		background-color: #3361ff !important;
	}

	@include defaultBtn;
	padding: 5px 15px;

	& + & {
		margin-left: 10px;
		&:hover {
			background-color: #000000;
		}
		@media (max-width: 500px) {
			margin-left: 0;
			margin-top: 10px;
		}
	}

	@media (max-width: 500px) {
		width: 100%;
	}
}

.teamsPopupBtnBlue {
	width: 130px;
	height: 40px;
	font-size: 12px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #3361ff;
}

.teamsPopupBtnRed {
	width: 130px;
	height: 40px;
	font-size: 12px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(230 46 46);
	&:hover {
		background-color: rgb(230 46 46) !important;
	}
}
.teamsPopupBtnWhite {
	color: black;
	&:hover {
		color: black;
	}
}

.customTop {
	display: flex !important;
	justify-content: space-between !important;
	padding-left: 20px !important;
	display: none !important;
}

.header {
	display: flex;
	justify-content: space-between;
	color: #7d8fb3;
	padding-bottom: 15px;
	font-weight: bold;
	align-items: center;
}

.square {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-left: 10px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.activeColor {
	color: red;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #fff;
}

.colors {
	display: flex;
	border-radius: 20px;
	padding: 8px;
}
::-webkit-input-placeholder {
	font-weight: bold;
	color: #7d8fb3;
	font-size: 14px;
}
.InnerDropDown {
	height: "10px";
	width: "10px";
	background-color: #7d8fb3;
}

.dropdown {
	width: 200px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	border-radius: 5px;
	background-color: #fff !important;
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 0.1px;
	padding: 0px;
	& > :first-child {
		padding: 8px 5px;
		width: 100%;
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;
		color: #000;
		background-color: #fff !important;
		svg {
			fill: #000;
		}
	}

	& > :nth-child(2) {
		box-shadow: 0px 9px 9px rgb(51 97 255 / 5%);
		max-height: none;
	}

	.colorType {
		padding: 0px;
	}
}

.addmembers {
	display: flex;
	align-items: center;
}

.line {
	border: 1px solid #dadee6;
	width: 250%;
	margin-left: -50px;
	opacity: 0.3;
}
.right_line {
	border-right: solid 1px #dadee64b;
}

.inputFull {
	// width: 100%;
	// border: 1px solid #ccc;
	border-radius: 8px !important;
	padding: 8px 15px !important;
	color: #7d8fb3 !important;
	font-weight: bold !important;
	font-size: 12px !important;
	width: 100% !important;
}
.inputs {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	font-size: 12px !important;
}
textarea::placeholder {
	font-size: 12 !important;
}

input::placeholder {
	font-size: 12 !important;
}

.dateText {
	font-weight: bold;
	display: grid;
	align-content: center;
	color: #7d8fb3;
	font-size: 14px;
	width: 60%;
}

.date {
	display: flex;
	padding: 13px 0px;
	margin: 0px 5px 0px 0px;
	padding-right: 30px;
}
.input_icon {
	display: flex;
	align-items: center;
	padding: 13px 0px;
	svg {
		width: 22px;
	}
}
.icon_place {
	padding: 0px 0px 5px 0px;
}

.icon {
	display: flex;
	justify-content: center;
	fill: #ccc;
	width: 30px;
	height: 30px;
}
.colorType {
	padding: 15px 20px 15px 15px;
	display: flex;
	align-items: center;
	gap: 5px;
}
.typePlays {
	height: 15px;
	width: 15px;
	border-radius: 20px;
}
.typecercel {
	background-color: white;
	height: 9px;
	width: 9px;
	border-radius: 20px;
	margin: 3px 0px 0px 3px;
}

.textarea {
	// background-color: $secondary;
	width: 100%;
	border-radius: 8px;
	padding: 8px 0px 8px 15px;
	color: #7d8fb3;
	overflow: hidden;
	min-height: 30px;
	max-height: 150px;
	resize: none;
	font-weight: bold;
	font-size: 12px;
}

.listeEvents {
	width: 90%;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 20px;
}

.titreTable {
	margin: 10px 0px;
}
.grouptool {
	display: flex;
	align-items: center;
}

.backSvg {
	fill: #7d8fb3;
	width: 7px;
	height: 11px;
	transform: rotate(180deg);
	cursor: pointer;
	opacity: 0.6;
	margin-right: 35px;
}

.nextSvg {
	fill: #7d8fb3;
	width: 7px;
	height: 11px;
	cursor: pointer;
	opacity: 0.6;
	margin-right: 35px;
}

.label {
	display: flex !important;
	justify-content: center !important;
}

.dateLabel {
	font-weight: bold;
}

.removeBorder {
	border: 0px !important;
	box-shadow: none !important;
}

.topCalendar {
	color: #7d8fb3;
	border: 1px solid #f4f4f4;
	border-radius: 10px 10px 0px 0px;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

.calendarHeader {
	display: none;
	padding: 20px;
	border-bottom: 2px solid #f4f4f4;
	margin-bottom: 10px;
	font-weight: bold;
}

.notificationText {
	margin-right: 10px;
}

.notification {
	display: flex;
	align-items: center;
}

.btnFull {
	width: 100% !important;
	background-color: transparent !important;
	border-radius: 8px !important;
	padding: 8px 15px !important;
	color: #7d8fb3 !important;
	font-weight: bold !important;
	font-size: 12px !important;
	display: flex;
}
.listProfiles {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	align-items: center;
}

.removeEvent {
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.icon_red {
	fill: red !important;
}

.containerBtnCeareEvent {
	padding: 30px;
	padding-bottom: 0px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.btnCreateEvent {
	width: 150px !important;
	height: 40px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	border-radius: 5px !important;
	background-color: #3361ff !important;
	color: #fff !important;
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 0.1px !important;
}

.btnSwitchViewMode {
	width: 40px;
	height: 40px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.planfeature {
	height: 100%;
	display: grid;
	justify-items: center;
	align-items: center;
	gap: 64px;
	align-content: center;
}
.planfeature_text {
	color: #7d8fb3;
	font-size: 24px;
	font-weight: bold;
}
.upgradeBtn {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	width: 145px !important;
	height: 40px !important;
	border-radius: 30px;
	box-shadow: 0px 10px 30px #8833ff1a;
	background-color: #3361ff !important;
	font-size: 12px !important;
	font-weight: bold !important;
}

.spanCompleted {
	font-size: 13px;
	color: #7d8fb3;
	font-weight: bold;
}

.actionsContent {
	width: 150px;
	padding: 10px;
	border-radius: 20px;
	background-color: #fff;
	box-shadow: $shadow;
	position: absolute;
	top: 100%;
	z-index: 3;
	border: 1px solid #eaeaea;
	display: flex;
	flex-direction: column;
	gap: 4px;
	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.dropResourcActionsContent {
	border-radius: 8px;
}

.resourcesHeaderIcon {
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
}

.dropResourceBtn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #7d8fb3;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	&:hover {
		color: #3361ff;
		.resourcesIcon {
			fill: #3361ff;
		}
		.checkStyles {
			fill: #3361ff;
		}
	}
	.checkStyles {
		width: 12px;
		height: 12px;
		fill: #7d8fb3;
	}
}

.sortByDate {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	width: 120px !important;
	height: 40px !important;
	border-radius: 5px !important;
	background-color: #fff !important;
	color: #000 !important;
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 0.1px !important;
	padding: 0px 10px !important;
	svg {
		fill: #000;
		transform: rotate(90deg);
		width: 12px;
		height: 12px;
	}
}

.completedIcon {
	display: flex;
	gap: 15px;
	align-items: center;
}

.noMargin {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.leftError {
	& > div > span {
		left: 0px;
		font-size: 10px;
	}
}

.documentFormUoImage {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	font-size: 12px;
	font-weight: bold;
	color: #555555;
	border-radius: 12px;
	align-items: center;
	width: 100%;
	height: 100%;
	grid-gap: 0px;
}

.upload input[type="file"] {
	appearance: initial;
	opacity: 0;
	cursor: pointer !important;
	position: absolute;
	width: 150px;
	padding: 9px;
}

.upload .choosen span {
	display: block;
}

.upload {
	width: 150px;
	height: 100%;
}

.formUp p {
	text-align: center;
	margin: 5px;
}

.customUploadBtn {
	width: 150px;
	height: 100%;
	background-color: #3361ff;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	border-radius: 5px;
	justify-content: center;
	text-align: center;
	padding: 10px;
	cursor: pointer;
}

.labelAttachment {
	width: 30px;
	height: 30px;
	padding: 0px;
	fill: #999;
	transform: rotate(90deg);
}

.gridTemplate {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 14px;
	grid-auto-flow: row;
}

.listFileName {
	text-overflow: clip;
	background-color: #fff;
	color: #7d8fb3;
	box-shadow: 0px 9px 9px rgb(51 97 255 / 5%);
	padding: 5px;
	width: 120px;
	white-space: nowrap;
	overflow: hidden;
}

.listFiles {
	overflow-x: hidden;
	overflow-y: auto;
	@include customScroll;
	display: grid;
	grid-gap: 2px;
	border-radius: 4px;
	max-height: 100px;
	grid-auto-rows: max-content;
}

.closeFile {
	width: 15px !important;
	height: 15px;
	border-radius: 50%;
	padding: 3px;
	fill: #7d8fb3;
	background-color: #ccc;
	cursor: pointer;
}

.wrapFileName {
	display: flex;
	gap: 2px;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.addPopupFramwork {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.selectedFramwork {
	border: 2px solid #3361ff;
	border-radius: 5px;
}
.disabledSelected {
	border: 2px solid #3361ff;
	border-radius: 5px;
	pointer-events: none;
}

.listBtnsAddframework {
	display: flex;
	padding: 25px 50px;
	justify-content: right;
	.addBtn {
		margin-left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		.svg {
			width: 10px;
			height: 10px;
		}
	}
}

.topPopupAddFramwork {
	padding: 20px 40px;
	text-align: center;
	border-bottom: 2px solid #f5f6f7;
	background: #fafbfc 0% 0% no-repeat padding-box;
	opacity: 1;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.addframworkTitle {
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
}

.addframworkTitleCenter {
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	text-align: center;
}

.gridRow {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(228px, 228px));
	grid-gap: 15px 15px;
	justify-content: center;
	align-items: center;
	overflow: auto;
	padding-top: 35px;
	@include customScroll;
	height: 100%;
	grid-auto-rows: max-content;
}

.gridRow .gridItem:hover {
	filter: contrast(0.7);
}
.gridRow .gridItem {
	transition: filter 0.5s ease;
	display: flex;
	cursor: pointer;
	background: #fafbfc 0% 0% no-repeat padding-box;
	text-align: center;
	flex-direction: column;
	align-items: stretch;
	max-width: 245px;
	min-height: 200px;
	border-radius: 5px;
	letter-spacing: 0px;
	color: #7d8fb3;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	position: relative;
}

.gridItem {
	padding: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.addPopup {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d08;
	border-radius: 10px;
	opacity: 1;
	height: 100%;
	overflow: hidden;
}

.NoResourcesFound {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	grid-column: 1 / span 4;
}

.search {
	width: 300px;
}

.backroundFrameworkCancel {
	background-color: #c3cad9;
}

.addBtn {
	background-color: #3361ff;
	width: 130px;
	height: 40px;
	border-radius: 30px;
	font-size: 12px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	box-shadow: 0px 10px 30px #3361ff26;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.eventContent {
	& > .eventColorType {
		width: 5px;
	}
}

.centerFrameworkName {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
}

.folderStyles {
	padding: 8px;
	width: 70px !important;
	height: 70px !important;
}

.bodyTypeContainer {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.extention {
	display: flex;
	width: 50px;
	height: 60px;
	border: 2px solid #3361ff;
	border-radius: 5px;
	opacity: 1;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #3361ff;
}
.repositoryPathDiv {
	display: flex;
	align-items: center;
	padding: 0px 40px;
}

.repositoryPath {
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	cursor: pointer;
	&:hover {
		color: #3361ff;
	}
}

.loaderResources {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.flexRow {
	display: flex;
}

.headerWithFilter {
	display: flex;
	flex-direction: column;
	border-bottom: 2px solid #f2f3f5;
}

.headerList {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.item {
	display: flex;
	justify-content: space-between;
	width: 100%;
	// padding: 15px 15px;
	align-items: center;
}

.sortByDate {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #ccc;
	color: #7d8fb3;
	font-size: 10px;
	font-weight: bold;
	width: 100%;
	height: 25px;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
	svg {
		fill: #7d8fb3;
		transform: rotate(90deg);
		width: 10px;
		height: 10px;
	}
}

.FilterByDate {
	width: 120px !important;
	font-size: 22px;
}

.listOfFilter {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding-top: 15px;
}

.iconMargin {
	margin-top: 3px;
}

.checkStyles {
	width: 10px;
	height: 10px;
	fill: #7d8fb3;
}

.filterIcon {
	width: 15px !important;
	height: 15px !important;
	fill: #7d8fb3;
}
