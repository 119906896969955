@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.body {
	height: 100%;
	min-height: 350px;
	width: 100%;
	padding: 20px;
	font-family:
		Gotham Book,
		sans-serif;
	overflow: auto;
	@include customScroll;
	padding-top: 0px;
	position: relative;
}

.controlItem {
	padding: 10px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: 10% 15% auto 10% 10% 10% 2%;
	background-color: #fff;
	padding: 10px;
	min-width: 700px;
	gap: 15px;
	align-items: center;
	cursor: pointer;
	.controlOwner {
		border-right: 2px solid #f5f6f7;
		height: 100%;
		width: 100%;
		& > .profileImage {
			height: 60px !important;
			width: 60px !important;
			margin: auto !important;
		}
	}
	svg {
		width: 24px;
		height: 24px;
	}
	display: flex;
}

.controlItem > :last-child {
	width: 40px; /* Set width of the last column to 50px */
}

.controlItem > :not(.descriptionItem):not(:last-child) {
	flex: 1; /* Make all columns except the first, description, and last one flexible */
}

.controlItem div {
	font-size: 12px;
	letter-spacing: 0px;
}

.riskComp {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0px 2px 5px #26334d08;
	border: 2px solid #f5f6f7;
	opacity: 1;
}

.autoMargin {
	margin: auto;
}

.descriptionItem {
	text-align: justify;
	font-size: 12px;
	width: 500px;
}

.item {
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ownerItem {
	height: 60px;
	width: 60px;
	margin: auto;
	font-size: 12px;
	font-weight: bold;
}

.sortable div.default {
	color: #d5dae6;
	visibility: hidden;
	opacity: 0;
}
.sortable div.up {
	color: #3361ff;
	visibility: visible;
	opacity: 1;
}
.sortable div.down {
	color: #3361ff;
	visibility: visible;
	opacity: 1;
}
div.up,
div.default,
div.down {
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center right;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 5;
}

.eyeHeader {
	position: relative;
}

.selectFilter {
	top: 28px;
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 4px;
	background-color: #fff;
	font-size: 10px;
	font-weight: bold;
	padding: 7px 8px;
	color: #242533;
	font-size: 10px;
	font-weight: bold;
	line-height: 16px;
	text-align: left;
	width: 100%;
	border-radius: 4px;
	z-index: 10;
	box-shadow: 0px 4px 4px 0px #00000014;
	width: 160px;
	right: -10px;
	.itemFilter {
		cursor: pointer;
		width: 100%;
		padding: 8px;
	}

	.activeFilter {
		color: #3361ff;
		background-color: rgba(51, 97, 255, 0.08);
		border-radius: 4px;
	}
}

.flexCenter {
	display: flex;
	align-items: center;
}

.controlItem {
	margin-bottom: 10px;
}

.tooltipContainer {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

// .tooltipTrigger {
//   text-decoration: underline;
//   color: blue;
// }

.tooltipContent {
	box-shadow: 0px 4px 4px 0px #00000014;
	visibility: hidden;
	width: 120px;
	background-color: #fff;
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 100%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltipContainer:hover .tooltipContent {
	visibility: visible;
	opacity: 1;
}

.sortItem:hover {
	& > .sortable div.default {
		visibility: visible;
		opacity: 1;
	}
}

.itemArticles {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.highlightedText {
	background-color: #ffeb3b;
	padding: 2px 0;
	border-radius: 2px;
	display: inline;
}
