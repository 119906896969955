/* eslint-disable react-hooks/exhaustive-deps */

.dropdownContainer {
	position: relative;
	display: inline-block;
}

.dropdownButton {
	color: #3361ff;
	font-weight: bold;
	font-size: 12px;
	border: none;
	cursor: pointer;
}

.dropdownContent {
	width: 290px;
	display: block;
	position: absolute;
	min-width: 160px;
	background: #fafbfc 0% 0% no-repeat padding-box;
	box-shadow: 0px 60px 120px #26334d0d;
	border-radius: 10px;
	padding: 12px 16px;
	z-index: 1;
}
