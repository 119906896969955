@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.main {
  display: flex;
  height: 100%;
  .container {
    flex-grow: 1;
  }
}

.container {
  margin: 30px;
  width: 100%;
}

.gridRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(228px, 228px));
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  // overflow: auto;
  margin-top: 35px;
  @include customScroll;
}

.gridRow .gridItem:hover {
  // filter: contrast(0.9);
  background-color: #edeff2;
}

.gridRow .gridItem {
  transition: filter 0.5s ease;
  display: flex;
  cursor: pointer;
  background: #fafbfc 0% 0% no-repeat padding-box;
  text-align: center;
  flex-direction: column;
  align-items: stretch;
  max-width: 245px;
  min-height: 200px;
  border-radius: 5px;
  letter-spacing: 0px;
  color: #7d8fb3;
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}

.gridItem {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.resourcesIcon {
  width: 35px;
  height: 35px;
  fill: #7d8fb3;
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #26334d08;
  // border-radius: 50%;
}

.resourcesHeaderIcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
}

.bodyTypeContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.extention {
  display: flex;
  width: 50px;
  height: 60px;
  border: 2px solid #3361ff;
  border-radius: 5px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: #3361ff;
}

.centerFrameworkName {
  display: flow;
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.frameworkCategory {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  color: #adb8cc;
}

.centerLoader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resourceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #edeff2;
  padding: 5px;
}

.resourceHeaderLeft {
  display: flex;
  align-items: center;
  gap: 30px;
  text-align: center;
  letter-spacing: 0px;
  color: #7d8fb3;
  opacity: 1;
  font-size: 14px;
  font-weight: bold;
}

.resourceHeaderRight {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 300px;
}

.searchInput {
  flex: 1;
}

.addButtonWrapper {
  position: relative;
}

.addButton {
  width: 32px !important;
  height: 32px !important;
  min-width: unset !important;
  padding: 0 !important;
  border-radius: 4px !important;
  background: #3361ff !important;
  border: none;
  color: white;
  font-size: 24px !important;
  line-height: 1 !important;
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  margin: 0 !important;

  span {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    width: 100% !important;
    margin-top: -2px !important;
  }

  &:hover {
    background: darken(#3361ff, 5%) !important;
  }
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 180px;
  z-index: 100;
  border: 1px solid #edeff2;
}

.dropdownItem {
  padding: 12px 16px;
  color: #7d8fb3;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f5f6f7;
    color: #3361ff;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #edeff2;
  }
}

.btnResource {
  cursor: pointer;
  &:hover {
    color: #3361ff;
    opacity: 0.9;
  }
}

.active {
  color: #3361ff;
}

.noResource {
  font-size: 14px;
  font-weight: bold;
  color: #7d8fb3;
}

.btnResoources {
  background-color: #3361ff;
  border-radius: 35px;
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  float: right;
}

.actionsContent {
  width: 150px;
  padding: 0 10px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: $shadow;
  position: absolute;
  top: 100%;
  z-index: 3;
  border: 1px solid #eaeaea;

  @media (max-width: 768px) {
    min-width: unset;
    width: 100%;
    position: static;
    border-radius: 0 0 7px 7px;
    margin-top: 10px;
  }
}

.actionsContentBtn {
  display: flex;
  justify-content: center;
  padding: 15px 5px;
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
  cursor: pointer;
  transition: all 0.15s;
  align-items: center;
}

.actions {
  float: right;
  width: 150px;
  position: relative;

  @media (max-width: 768px) {
    position: static;
  }
}

.addBtnResource {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textHeaderLeftIcon {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #26334d08;
  border-radius: 5px;
  opacity: 1;
  width: 40px;
  height: 40px;
  padding: 10px;
  fill: #c3cad9;
  cursor: pointer;
  rotate: 180deg;
}

.btnResource {
  display: flex;
  align-items: center;
  gap: 25px;
}

.teamsPopupBtn {
  width: 112px;
  height: 40px;
  font-weight: bold;
  border-radius: 30px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #26334d08;
  border-radius: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
  @include defaultBtn;
  &:hover {
    background-color: #fff !important;
  }
  svg {
    fill: #c3cad9 !important;
    opacity: 1;
    width: 15px;
    height: 15px;
  }
}

.textHeaderReigthText {
  text-align: left;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
}

.btnBlue {
  width: 112px;
  height: 40px;
  font-weight: bold;
  border-radius: 30px !important;
  color: #ffffff !important;
  background: #3361ff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #26334d08;
  border-radius: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
  @include defaultBtn;
  &:hover {
    background-color: #3361ff !important;
  }
  svg {
    fill: #ffffff80 !important;
    opacity: 1;
    width: 15px;
    height: 15px;
  }

  .textHeadersave {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
  }
}

.textHeaderRiegth {
  display: flex;
  gap: 20px;
}

.reportFormBodyLeft {
  width: 100%;
  border-right: 2px solid #f5f6f7;
  height: 100%;
  padding-top: 20px;
}

.reportFormBodyRiegth {
  width: 30%;
  height: 100%;
  padding: 30px;
}

.reportFormBody {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.titleReport {
  display: flex;
  width: 100%;
  height: 90px;
  border: 2px solid #f5f6f7;
  align-items: center;
  padding: 20px;
  background: #fafbfc 0% 0% no-repeat padding-box;
  font-weight: bold;
  color: #7d8fb3;
  border: 1px solid #edeff2;
  border-radius: 10px 10px 10px 10px;
}

.inputStyle {
  width: 100%;
  margin: 0px !important;
}

.titleReportLeft {
  width: 20%;
  min-width: 135px;
  height: 100%;
  border-right: 2px solid #f5f6f7;
  font-size: 16px;
  font-weight: bold;
  color: #4d5e80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleReportReight {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &,
  input {
    text-align: left;
    letter-spacing: 0px;
    color: #4d5e80;
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
  }
}

.controlCardHeaderIconPopup {
  height: 25px;
  width: 25px;
}
.controlCardHeaderLeftPopup {
  display: flex;
  align-items: center;
  gap: 20px;
}

.controlCardHeaderPopup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* UI Properties */
  background: #fafbfc 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  padding: 10px 20px;
  height: 65px;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
  border: 1px solid #edeff2;
}

.controlCardPopup {
  width: 100%;
  border: 1px solid #edeff2;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;
  height: 100%;
}
.controlCardBodyPopup {
  background-color: #fff;
}

.arrowInverst {
  width: 30px;
  height: 30px;
  padding: 9px;
  fill: #c3cad9;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #edeff2;
  transform: rotate(90deg);
}

.iframePdfLoader {
  border: 2px solid #edeff2;
  border-radius: 8px;
  margin: 25px 0px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dotsStyles {
  border-radius: 50%;
}

.dropResourceBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: #3361ff;
    .resourcesIcon {
      fill: #3361ff;
    }
  }
}

.dropResourcActionsContent {
  border-radius: 8px;
}

.deleteLoad {
  padding-bottom: 10px;
  padding-right: 8px;
  padding-top: 5px;
}

.divRepository {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 8px;
  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.repositoryInput {
  border: 1px solid #c3cad9;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
  padding: 8px;
  width: 100%;
  height: 35px;
  &::placeholder {
    color: #7d8fb3;
    font-size: 12px;
    font-weight: bold;
  }
}

.repositoryBtn {
  width: 150px;
  height: 30px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3361ff;
  color: #edeff2;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.folderStyles {
  padding: 8px;
  width: 70px;
  height: 70px;
}

.repositoryPathDiv {
  display: flex;
  align-items: center;
}

.repositoryPath {
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
  cursor: pointer;
  &:hover {
    color: #3361ff;
  }
}

.listOfRepositories {
  overflow: auto;
  height: 150px;
  @include customScroll;
  padding-bottom: 10px;
}

.repositoryInput {
  width: 100%;
}

.radioRepository {
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #c3cad9;
  cursor: pointer;
  &:hover {
    background-color: #f5f6f7;
  }
}

.repositoriesContainer {
  padding: 16px;
}

.repositoryInputRadio {
  border: 1px solid #c3cad9;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
  padding: 8px;
  height: 15px;
  width: 15px;
  &::placeholder {
    color: #7d8fb3;
    font-size: 12px;
    font-weight: bold;
  }
}

.repoBtns {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 8px;
}

.repoDeleteBtn {
  background-color: red;
  color: #ffffff !important;
}

.repoMessage {
  font-size: 13px;
  font-weight: bold;
  color: #7d8fb3;
  text-align: justify;
}

.spanRepoMessage {
  color: #4d5e80;
}

.NoRepoMessage {
  color: #7d8fb3;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.desableClick {
  pointer-events: none;
  color: #c3cad9;
}

.sortDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #7d8fb3;
}

.sortInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  input {
    margin: 0px;
  }
}

.resourcesMain {
  width: 100%;
  height: 100%;
}

.createAndSort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin: 15px 0px;
}

.noPadding {
  padding: 0px;
}

.cardContent {
  height: 100%;
}

.upload input[type="file"] {
  appearance: initial;
  opacity: 0;
  cursor: pointer !important;
  position: absolute;
  width: 100%;
  padding: 9px;
}

.upload .choosen span {
  display: block;
}

.upload {
  width: 100%;
  height: 100%;
}

.formUp p {
  text-align: center;
  margin: 5px;
}

.customUploadBtn {
  width: 100%;
  height: 100%;
  background-color: #3361ff;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  padding: 9px;
  cursor: pointer;
}

.documentDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actions {
  position: relative;
}

.btnResoources {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: var(--primary-color);
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--primary-color-dark);
  }
}

.actionsContent {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  min-width: 180px;
}

.actionsContentBtn {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: var(--hover-color);
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.repositoryInput {
  flex: 1;
  padding-right: 40px; /* Space for the icon */
}

.iconWrapper {
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.checkIcon {
  position: absolute;
  transition: opacity 0.2s ease;
  opacity: 1;
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
  fill: #ffffff;
  border-radius: 50%;
  background-color: #c3cad9;
  padding: 5px;
}

.checkIconChecked {
  position: absolute;
  transition: opacity 0.2s ease;
  opacity: 1;
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
  fill: green;
  border-radius: 50%;
  background-color: #fff;
  padding: 5px;
}

.deleteIcon {
  position: absolute;
  cursor: pointer;
  transition: opacity 0.2s ease;
  opacity: 0;
  color: red;
  font-size: 18px;
  line-height: 24px;
}

.inputWrapper:hover .checkIcon {
  opacity: 0;
}

.inputWrapper:hover .deleteIcon {
  opacity: 1;
}
