.avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.center {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
