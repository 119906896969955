@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.chatMain {
	height: 100%;
	width: 100%;
}

.main {
	display: flex;
	gap: 10px;
	height: 100%;
}

.noReportMsg {
	display: flex;
	align-items: center;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	justify-content: center;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	width: 100%;
}

.sidebarContainer {
	width: 350px;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.assistantsContainer {
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 2px 5px #26334d08;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;

	.assistantOption {
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 10px 15px;
		border-radius: 8px;
		cursor: pointer;
		transition: all 0.2s ease;
		color: #6B7A99;
		background: #ffffff;

		&:hover {
			background: #f7f9fb;
		}

		&.activeAssistant {
			background: #3361ff;
			color: #fff;

			.assistantIcon {
				fill: #fff;
			}
		}

		.assistantIcon {
			width: 16px;
			height: 16px;
			fill: #6B7A99;
		}

		span {
			font-size: 14px;
			font-weight: 500;
			color: inherit;
		}
	}
}

.historiqueContainer {
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 2px 5px #26334d08;
	flex-grow: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.newChatButton {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 15px;
	color: #7d8fb3;
	cursor: pointer;
	border-bottom: 1px solid #f2f3f5;
	transition: all 0.2s ease;

	&:hover {
		background: #f7f9fb;
	}

	.addNewChatIcon {
		width: 12px;
		height: 12px;
		fill: currentColor;
	}

	span {
		font-size: 14px;
		font-weight: 500;
	}
}

.historiqueChat {
	flex-grow: 1;
	overflow-y: auto;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #f2f3f5;
	}

	&::-webkit-scrollbar-thumb {
		background: $blue;
	}
}

.messageTilesHistorique {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	height: 70px;
	position: relative;
}

.messageTiles {
	text-align: left;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 13px;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	padding-right: 10px;
}

.messageSenderDate {
	text-align: left;
	letter-spacing: 0px;
	color: #adb8cc;
	font-size: 12px;
	font-weight: bold;
}

.removeIconHistorique {
	width: 15px;
	height: 15px;
	fill: #000;
}

.tilesSender {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 95%;
}
.planfeature {
	height: 100%;
	display: grid;
	justify-items: center;
	align-items: center;
	gap: 64px;
	align-content: center;
}
.planfeature_text {
	color: #7d8fb3;
	font-size: 24px;
	font-weight: bold;
}
.upgradeBtn {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	width: 145px !important;
	height: 40px !important;
	border-radius: 30px;
	box-shadow: 0px 10px 30px #8833ff1a;
	background-color: #3361ff !important;
	font-size: 12px !important;
	font-weight: bold !important;
}

.TilesHistorique {
	width: 100%;
	position: absolute;
	background: #f2f3f5 0% 0% no-repeat padding-box;
	z-index: 1;
	padding: 15px;
	padding-right: 20px;
}

.tilesSenderNewMessage {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	cursor: pointer;
}

.addNewChatIcon {
	width: 12px;
	height: 12px;
	fill: #7d8fb3;
}

.messageTilesHeader {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
}

.chatIcons {
	display: flex;
	gap: 8px;
}

.actionsContent {
	width: 350px;
	padding: 0 10px;
	border-radius: 20px;
	background-color: #fff;
	box-shadow: $shadow;
	position: absolute;
	top: 100%;
	z-index: 3;
	border: 1px solid #eaeaea;
	top: 50px;

	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.dropResourceBtn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover {
		color: #3361ff;
		.resourcesIcon {
			fill: #3361ff;
		}
	}
}

.dropResourcActionsContent {
	border-radius: 8px;
	background-color: #fafbfc;
	font-size: 12px;
	font-weight: bold;
	color: #4d5e80;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 300px !important;
	left: -18%;
	top: 80px;
}

.relative {
	position: relative;
}

.userItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropHeader {
	font-size: 14px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.messateText {
	/* UI Properties */
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.btnSaveMessage {
	width: 100% !important;
	height: 50px !important;
	/* UI Properties */
	background: #3361ff 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 10px 30px #26334d0d !important;
	border-radius: 30px !important;
	opacity: 1;
	text-align: center !important;
	font-size: 13px;
	font-weight: bold;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.userList {
	padding: 0px 20px;
}

.overflow {
	overflow-x: initial;
	overflow-y: initial;
}

.activeChat {
	background-color: #f7f9fb;
}

.assistantSelector {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 15px;

	.assistantOption {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 12px 15px;
		border-radius: 10px;
		cursor: pointer;
		transition: all 0.2s ease;
		color: #4d5e80;
		background: #ffffff;
		box-shadow: 0px 2px 5px #26334d08;

		&:hover {
			background: #f7f9fb;
		}

		&.activeAssistant {
			background: #3361ff;
			color: #fff;

			.assistantIcon {
				fill: #fff;
			}
		}

		.assistantIcon {
			width: 18px;
			height: 18px;
			fill: #4d5e80;
		}

		span {
			font-size: 13px;
			font-weight: bold;
		}
	}
}

.sidebarHeader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: #f2f3f5;
	z-index: 2;
	padding: 15px;
}
