@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

$chip-y-spacing: 15px;
$chip-x-spacing: 5px;
$chip-button-width: $chip-y-spacing + $chip-x-spacing * 2;
$chip-border-radius: 15px;
$chip-background: #555;
$chip-color: #fff;
$chip-min-height: 36px;

.container {
	position: relative;

	input {
		width: 100%;
		padding: 10px 15px;
		font-size: 12px;
		&::placeholder {
			font-size: 12px;
		}
	}

	.tagsDropDown {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: auto;
		max-height: 140px;
		overflow: auto;
		background-color: #fff;
		box-shadow: none;
		border: 1px solid #eaeaea;
		border-radius: 5px;
		z-index: 3;
		@include customScroll;
		ul {
			display: flex;
			flex-direction: column;
			gap: 2px;

			li {
				padding: 4px 2px;
				cursor: pointer;
				font-size: 12px;
				border-top: 1px solid rgba(247, 249, 251, 0.5);
				padding: 8px 5px;
			}
		}
	}
}

/* Update these styles based on your requirements and component structure */

.wrapper {
	position: relative;
}

.chipsInput {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	--primary: #fff;
	--secondary: #111;
	--invalid: #f00;
	color: var(--secondary);
}

.chipsInput > label {
	opacity: 0.8;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	color: inherit;
}

.chipsInput > p {
	font-size: 12px;
	opacity: 0.75;
	margin: 0 0.25rem;
	color: inherit;
}

.chipsInput > .inner {
	width: 100%;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: none;
	border: 2px solid #f5f6f7;
	border-radius: 5px;
	@include customScroll;
	box-sizing: border-box;
	position: relative;
	color: #7d8fb3;
}

.chipsInput > .inner > input {
	border: none;
	outline: none;
	position: relative;
	background: transparent;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	color: #7d8fb3;
	font-size: 12px;
}

.chip {
	padding: 0.25rem 0.5rem;
	box-sizing: border-box;
	background: var(--secondary);
	border-radius: 4px;
	color: var(--primary);
	font-size: 12px;
	padding-right: 1.5rem;
	position: relative;
}

.chip > svg {
	border: none;
	background: transparent;
	color: transparent;
	position: absolute;
	top: 50%;
	right: 0.15rem;
	transform: translateY(-50%);
}

.chip > svg {
	cursor: pointer;
	fill: var(--primary);
	width: 15px;
	height: 15px;
}

.limit {
	float: right;
}

.relativeDive {
	position: relative;
	width: 100%;
}

.chipContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	padding: 10px 15px;
}

.noTags {
	border: 0px !important;
	padding: 0px !important;
	font-size: 11px;
	font-weight: bold;
}
