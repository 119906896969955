.editor {
	box-sizing: border-box;
	cursor: text;
	padding: 16px;
	border-radius: 2px;
	margin-bottom: 2em;
	background: #fefefe;
	position: relative;
	width: 100%;
	font-size: 12px;
	color: #7d8fb3;
	font-weight: bold;
}

.editor :global(.public-DraftEditor-content) {
	min-height: 50px;
}
