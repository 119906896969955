@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.main {
	height: 100%;
	border: 2px solid #edeff2;
	border-radius: 15px;
	margin-bottom: 0px;
}

.header {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	height: 80px;
	align-items: center;
	padding: 0px 20px;
	border-bottom: 2px solid #edeff2;
}

.rieghtHeaderHiegth {
	height: 100%;
}

.rieghtHeader {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 12px;
}

.titleCustomFramework {
	cursor: pointer;
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	letter-spacing: 0px;
	color: #3361ff;
	opacity: 1;
	border-right: 2px solid #edeff2;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 15px;
}

.leftHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.addBtn {
	background-color: #3361ff;
	width: 130px;
	height: 40px;
	border-radius: 30px;
	font-size: 12px;
	padding: 0px;
	letter-spacing: 0.3px;
	font-weight: bold;
	box-shadow: 0px 10px 30px #3361ff26;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;

	&.cancelBtn {
		background-color: #fff;
		color: #3361ff;
		border: 1px solid #3361ff;
		box-shadow: none;
	}
}

.subHeader {
	display: flex;
	justify-content: space-between;
	height: 80px;
	align-items: center;
	padding: 0px 20px;
	border-bottom: 2px solid #edeff2;
	height: 11%;
}

.controls {
	padding: 0px 20px;
	text-align: center;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 14px;
	font-weight: bold;
}

.gridSearch {
	display: flex;
	align-items: center;
	padding: 0px 20px;
	.grid {
		background-color: #fff;
		border-radius: 30px;
		padding: 12px;
		width: 100px;
		display: flex;
		justify-content: space-around;
		margin-right: 20px;
	}
}
.sort {
	padding: 0px 5px;
	/* UI Properties */
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	display: flex;
}

.sortItem {
	padding: 0px 10px;
	cursor: pointer;
}

.sortItemActive {
	color: #3361ff;
}

.search {
	width: 300px;
	height: 35px;
	& > input {
		font-size: 12px;
		&::placeholder {
			font-size: 12px;
		}
	}
}

.body {
	height: 78%;
}

.table {
	padding: 1em 0em;
	display: flex;
	flex-direction: column;
	height: 100%;

	.tableHeader {
		display: flex;
		height: 14%;
		padding: 0em 1em;
		div {
			letter-spacing: 0px;
			color: #7d8fb3;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
	}

	.TableBody {
		height: 86%;
		overflow: auto;
		padding: 0em 1em;
		@include customScroll;
		display: grid;
		gap: 15px;
	}

	.row {
		display: flex;
		align-items: center;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 5px #26334d08;
		border-radius: 5px;
		opacity: 1;
		cursor: pointer;
		div {
			letter-spacing: 0px;
			color: #4d5e80;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
		& > *:first-child {
			border-right: 2px solid #f5f6f7;
			padding: 20px 22px;
		}
	}
}

.tableAction {
	min-width: 50px;
}

.newFrameworkAction {
	min-width: 100px;
	max-width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.name {
	min-width: 240px;
	text-align: center;
}

.description {
	width: 100%;
	padding: 15px;
	text-align: justify !important;
}

.nameHeader {
	padding: 15px;
	text-align: left !important;
	padding-left: 40px !important;
}

.newFramework {
	background-color: #3361ff;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	width: 143px;
	height: 38px;
	padding: 0px;
}

.newFrameworkNameAssign {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.newFrameworkDescrption {
	border-right: 2px solid #f5f6f7;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.bodyDataControls {
	display: flex;
	justify-content: center;
	padding: 50px;
	text-align: center;
	margin: auto;
}

.newFrameworkRow {
	padding: 10px 0px;
}

.divRepository {
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 8px;
}

input[type="checkbox"],
input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid #ccc;
	outline: none;
	transition: all 0.3s ease;
	margin-right: 10px;
	box-shadow: inset 0 0 0 3px #fff;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
	background-color: #3361ff;
	border-color: #3361ff;
	box-shadow: inset 0 0 0 3px #fff !important;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
	box-shadow: 0 0 3px #3361ff;
}

.repoMessage {
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	text-align: justify;
}

.spanRepoMessage {
	color: #4d5e80;
}

.repoBtns {
	display: flex;
	align-items: center;
	justify-content: right;
	gap: 8px;
}

.repositoryBtn {
	width: 150px;
	height: 30px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #3361ff;
	color: #edeff2;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
}

.svgIconsEmpty {
	display: block;
	margin: auto;
	width: 25px;
	height: 25px;
	//margin-right: 10px;
	fill: #ffffff;
	border-radius: 50%;
	cursor: pointer;
	padding: 5px;
	border: #c3cad9 2px solid;
}

.CustomSvgIcons {
	display: block;
	margin: auto;
	width: 25px;
	height: 25px;
	// margin-right: 10px;
	fill: #ffffff;
	border-radius: 50%;
	background-color: #c3cad9;
	padding: 5px;
}

.btnActionBulk {
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	background-color: #f3f3f8;
	display: flex;
	align-items: center;
	width: 180px;
	height: 32px;
	padding: 7px 8px 7px 8px;
	justify-content: space-between;
	border-radius: 8px;

	svg {
		width: 10px;
		height: 10px;
		transform: rotate(90deg);
		fill: #7d8fb3;
	}
}

.activeFilter {
	color: #3361ff;
	border: 1px solid #3361ff;
	// background-color: rgba(51, 97, 255, 0.08);
	border-radius: 4px;
}

.filterContainerActions {
	display: flex;
	position: relative;
	gap: 4px;
	align-items: center;
	min-width: 120px;
}
