@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.svgIcons {
	height: 35px;
	width: 45px;
	cursor: pointer;
}

.settingsIcon {
	width: 40px;
	height: 40px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 12px;
	margin-left: 0px;
	padding: 10px;
	fill: #b6bdc4;
	cursor: pointer;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
}

.imageProfile {
	display: inline-grid;
	display: -ms-inline-grid;
	display: -moz-inline-grid;
}

// SETTING MODEL STYLES

.moduleBody {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0px 75px;
	.inputContainer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		input {
			background: #ffffff 0% 0% no-repeat padding-box;
			box-shadow: 0px 2px 5px #26334d0d;
			border: 2px solid #f5f6f7;
			border-radius: 30px;
			opacity: 1;
			width: 350px;
			// padding: 12px 20px 12px 20px;
			font-size: 12px;
			font-weight: bold;
		}
		.comments {
			color: #adb8cc;
			margin: 20px;
			font-size: 10px;
			opacity: 1;
			font-weight: bold;
			font-size: 12px;
			max-width: 300px;
			text-align: justify;
		}
	}
	.labelContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 55%;

		.comments {
			color: #adb8cc;
			margin: 20px;
			font-size: 10px;
			opacity: 1;
			font-weight: bold;
			font-size: 12px;
			max-width: 300px;
		}
	}
}
.settingIcon {
	display: flex !important;
	align-items: center !important;
	background: #3361ff 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 10px 30px #8833ff1a !important;
	border-radius: 30px !important;
	padding-right: 14px !important;
	color: #ffffff !important;
	font-weight: bold !important;
	font-size: 12px !important;
	opacity: 1;
	height: 35px !important;
	cursor: pointer !important;
}
.button {
	display: flex;
	align-items: center;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px rgb(136 51 255 / 10%);
	border-radius: 30px;
	padding: 2px 17px;
	font-weight: bold;
	font-size: 12px;
	opacity: 1;
	cursor: pointer;
	color: #7d8fb3;
}
.label {
	color: #4d5e80;
	font-weight: bold;
	margin: 20px;
	font-size: 12px;
	margin-bottom: 0px;
}
.containerModel {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
}
.headerModel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fafbfc 0% 0% no-repeat padding-box;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	padding: 0px 40px;
	font-weight: bold;
	font-size: 12px;
	border-bottom: #f5f6f7 2px solid;
	height: 10%;
	.leftSection {
		display: flex;
		width: 36%;
		flex-direction: row;
		justify-content: space-between;
	}
}
.headerUserSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	padding: 0px 40px;
	font-weight: bold;
	font-size: 12px;
	border-bottom: #f5f6f7 2px solid;
	// height: 12%;
	padding: 13px 40px;
	.leftSection {
		display: flex;
		width: 36%;
		flex-direction: row;
		justify-content: space-between;
	}
}
.settingsIconNoBackground {
	display: block;
	width: 48px;
	height: 48px;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 0px;
	border-radius: 25%;
	padding: 15px;
	fill: #ffffff;
	cursor: pointer;
}
.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;
}
.menu {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}
.menuUser {
	display: flex;
	align-items: center;
	margin-left: 15px;
}
.IconNoBackgroundRetate {
	display: block;
	width: 19px;
	height: 19px;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 0px;
	border-radius: 25%;
	fill: #c3cad9;
	cursor: pointer;
	transform: rotate(90deg);
}
.IconNoBackgroundColor {
	display: block;
	width: 48px;
	height: 48px;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 0px;
	border-radius: 25%;
	padding: 15px;
	fill: #c3cad9;
	cursor: pointer;
}

.bodyContainer {
	height: 90%;
	overflow-y: auto;
	overflow-x: hidden;
	@include customScroll;
}

.forScroll {
	height: 96%;
	overflow: auto;
	@include customScroll;
}

.labeltoDiv {
	width: 150px;
	text-align: left;
	height: 30px;
}
.tableRow {
	display: table-row;
	border-radius: 10px;
	overflow: hidden;
}

.tableCell {
	display: table-cell;
	border: 2px solid #f2f3f5;
	padding: 9px 17px;
	text-align: center; /* add text alignment */
	vertical-align: middle; /* vertically center the content */
	color: #6b7a99;
	svg {
		display: block;
		margin: auto;
		width: 20px;
		height: 20px;
		margin-right: 10px;
		fill: #c3cad9;
	}
	p {
		margin: 5px 0 0;
		font-size: 14px;
	}
}

.tableCell:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.tableCell:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.CustomSvgIcons {
	display: block;
	margin: auto;
	width: 25px;
	height: 25px;
	// margin-right: 10px;
	fill: #ffffff;
	border-radius: 50%;
	background-color: #c3cad9;
	padding: 5px;
}
.editSvg {
	display: block;
	margin: auto;
	width: 40px;
	height: 40px;
	cursor: pointer;
	// margin-right: 10px;
	fill: #c3cad9;
	padding: 8px;
	transform: rotate(90deg);
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border: 2px solid #f5f6f7;
	border-radius: 30px;
	opacity: 1;
}
.svgIconsEmpty {
	display: block;
	margin: auto;
	width: 25px;
	height: 25px;
	//margin-right: 10px;
	fill: #ffffff;
	border-radius: 50%;
	cursor: pointer;
	padding: 5px;
	border: #c3cad9 2px solid;
}
.switchBox {
	display: flex;
	align-items: center;
	background: #f5f6f7 0% 0% no-repeat padding-box;
	border-radius: 10px;
	color: #ffffff;
	font-weight: bold;
	font-size: 12px;
	padding: 7px;
	opacity: 1;
	flex-direction: row;
	width: 85px;
	flex-wrap: nowrap;
	justify-content: space-around;
}

//  TABLE BODY  STYLES

.tableContent {
	border-collapse: collapse;
	font-size: 0.9em;
	min-width: 400px;
	width: 100%;
	thead : {
		background-color: #3361ff;
		color: #ffffff;
		text-align: left;
		font-weight: bold;
	}
	tr {
		border-bottom: 1px solid #dddddd;
	}
	th,
	td {
		padding: 15px 40px;
	}

	th {
		position: sticky; /* Add sticky header */
		top: 0;
		z-index: 10;
		background-color: #ffffff;
	}
}
.BodyUserContainer {
	height: 73%;
}

.BodyUserContainerInvited {
	height: 100%;
}

.profileBody {
	display: flex;
	justify-content: space-around;
	padding: 5%;
	flex-direction: row;
	height: 70%;
	gap: 6%;
}
.card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: 200px;
	align-items: center;
	width: 30%;
	border-radius: 10px;
	background: #fafbfc 0% 0% no-repeat padding-box;
}

.info {
	background-color: #fafbfc !important;
	width: 70%;
	padding: 20px;
	border-radius: 10px;
}
.archive {
	background-color: #fafbfc;
	width: 70%;
	padding: 20px;
	border-radius: 10px;
	border: #3361ff 2px solid;
}
.archiveBody {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: auto;
	height: 90%;
	padding: inherit;
	font-size: 12px;
	@include customScroll;
}
.eachArchive {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	font-weight: bold;
	font-size: 12px;
	padding: 15px 0;
	text-align: left;
}
.nameContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 40%;
	align-items: center;
}
.headInfo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 10%;
	padding: 0px 20px;
	font-weight: bold;
	font-size: 12px;
	border-bottom: #f5f6f7 2px solid;
}

.customInput {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	input {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 5px #26334d0d;
		border: 2px solid #f5f6f7;
		border-radius: 14px;
		opacity: 1;
		padding: 12px;
		color: #6b7a99;
	}
	label {
		font-size: 12px;
		font-weight: bold;
		color: #6b7a99;
		margin-bottom: 5px;
	}
}
.formInfo {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 90%;
	padding: 25px 20px;
	gap: 5%;
	overflow: auto;
	@include customScroll;
}

.select {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d0d;
	border: 2px solid #f5f6f7;
	border-radius: 30px;
	opacity: 1;
	width: 350px;
	padding: 12px 20px 12px 20px;
	font-size: 12px;
	color: #7d8fb3;
	cursor: pointer;
}
.selectProfile {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px rgb(38 51 77 / 5%);
	border: 2px solid #f5f6f7;
	border-radius: 14px;
	opacity: 1;
	padding: 12px;
	color: #7d8fb3;
	display: flex;
	justify-content: space-between;
	width: 227.3px;
	font-size: 12px;
}
.optionsContainer {
	position: absolute;
	z-index: 1;
	left: 0px;
	width: 100%;
	border-radius: 10px;
	background-color: whitesmoke;
	padding: 10px 24px;
	text-align: left;
	letter-spacing: 0px;
	color: #7d8fb3;
	opacity: 1;
	font-size: 12px;
	cursor: pointer;
}
p .nameInitial {
	height: 50px;
	width: 50px;
	display: flex;
	border-radius: 25px;
	align-items: center;
	justify-content: center;
	color: #ccc;
	font-weight: bold;
}
.nameInitialEdit {
	width: 150px !important;
	height: 150px !important;
}

.selectClassName {
	width: 100%;
	margin-top: 15px;
	svg {
		fill: #7d8fb3;
	}
	& > div:first-child {
		border-radius: 30px;
		padding: 15px;
	}

	& > div {
		color: #7d8fb3;
		font-size: 12px;
		font-weight: bold;
	}
}

.labelColor {
	color: #4d5e80;
}

.orgLogo {
	width: 150px;
	height: 150px;
	border: 3px dashed #f5f6f0;
	border-radius: 8px;
	object-fit: contain;
}

.upload input[type="file"] {
	appearance: initial;
	opacity: 0;
	width: 150px;
	cursor: pointer;
	position: absolute;
	left: 0%;
}

.upload .choosen span {
	display: block;
}

.formUp {
	width: 100%;
	height: 110px;
	position: relative;
}

.formUp p {
	text-align: center;
	margin: 5px;
}

.uploadIcon {
	width: 75px;
	height: 75px;
	fill: #c3cad9;
}

.tbodyStyles {
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	overflow-y: auto;
	height: 90%;
	overflow-y: auto;
	overflow-x: hidden;
	@include customScroll;
}

.theadStyles {
	text-align: left;
	letter-spacing: 0px;
	color: #7d8fb3;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.theadStyles > tr > *:last-child {
	text-align: center;
}

.theadStyles > tr > *:first-child {
	width: 100px;
	padding-left: 52px;
}

.tbodyStyles > tr > *:first-child {
	width: 100px;
	padding-left: 52px;
}

.selectRole {
	width: 210px;
	height: 50px;
	margin-top: 2px;
	margin-left: 13px;
	svg {
		fill: #7d8fb3;
	}
	& > div:first-child {
		border-radius: 5px;
		padding: 0px 15px;
		height: 50px;
	}

	& > div {
		color: #7d8fb3;
		font-size: 12px;
		font-weight: bold;
	}
}

.inputUser {
	width: 210px !important;
	border-radius: 5px !important;
	margin-left: 15px;
	margin-top: 2px;
	& > div > input {
		height: 50px;
		border-radius: 5px !important;
		background: #ffffff 0% 0% no-repeat padding-box !important;
		box-shadow: 0px 2px 5px #26334d08 !important;
		opacity: 1;
		color: #7d8fb3;
		font-size: 12px;
		font-weight: bold;
	}
}

.uploadUserImage input[type="file"] {
	appearance: initial;
	opacity: 0;
	width: 150px;
	height: 150px;
	cursor: pointer;
	position: absolute;
	left: 0px;
	border-radius: 50%;
}

.UserFormUoImage {
	position: relative;
}
.UserFormUoImage input[type="file"] {
	appearance: initial;
	height: 15px;
}

.uploadUserImage .choosen span {
	display: block;
}

.formUpUser {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.formUpUser p {
	text-align: center;
	margin: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.uploadIcon {
	width: 25px;
	height: 25px;
	fill: #c3cad9;
}

.commentsUser {
	text-align: center;
	letter-spacing: 0px;
	color: #adb8cc;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.imageUsers {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	object-fit: cover;
}

.imagecommentUser {
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
}

.formUpImage {
	position: relative;
	input {
		height: 10px;
	}
}

.ImageLogo input[type="file"] {
	appearance: initial !important;
	opacity: 0 !important;
	width: 150px !important;
	height: 150px !important;
	cursor: pointer !important;
	position: absolute !important;
	left: 0px !important;
	border: 2px dashed #4d5e80 !important;
	border-radius: 5px;
}

.ImageLogo {
	width: 150px !important;
	height: 150px !important;
	cursor: pointer !important;
	position: relative;
	left: 0px !important;
	border: 2px dashed #f5f6f7 !important;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		display: flex;
		svg {
			width: 50px !important;
			height: 50px !important;
		}
	}
}

.headerStyleText {
	font-size: 14px;
	font-weight: bold;
	color: #6b7a99;
	margin-bottom: 20px;
}

.AddUserBtn {
	display: flex !important;
	align-items: center !important;
	background: #3361ff 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 10px 30px #8833ff1a !important;
	border-radius: 30px !important;
	padding-right: 14px;
	color: #ffffff !important;
	font-weight: bold !important;
	font-size: 12px !important;
	opacity: 1 !important;
	height: 35px !important;
	width: 145px !important;
	cursor: pointer !important;
	justify-content: space-between !important;
	svg {
		width: 18px;
		height: 18px;
		fill: #fff;
	}
}

.invitForm {
	display: flex;
	flex-direction: column;
	margin: 95px 50px;
}

.InvitFormInfo {
	display: flex;
	background-color: #fafbfc;
	min-height: 170px;
	align-items: center;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;
	padding-bottom: 15px;
	gap: 8px;
}

.btnInvitCancel {
	width: 150px !important;
	background-color: #fff !important;
	color: #adb8cc !important;
	margin-right: 15px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	background: #ffffff 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 8px #26334d08 !important;
	border-radius: 30px !important;
	font-size: 12px !important;
	font-weight: bold !important;
}

.btnInvit {
	width: 150px !important;
	background-color: #3361ff !important;
	color: #fff !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-evenly !important;
	background: #3361ff 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 10px 30px #8833ff1a !important;
	border-radius: 30px !important;
	opacity: 1 !important;
	font-size: 12px !important;
	font-weight: bold !important;
}

.listInvitBtn {
	height: 84px !important;
	display: flex !important;
	justify-content: right !important;
	padding: 22px !important;
}

.memberIconText {
	display: flex;
	align-items: center;
	color: #6b7a99;
	font-size: 14px;
	font-weight: bold;
}

.btnwhite {
	width: 150px;
	min-width: 150px;
	max-width: 150px;
}

.editSvgRemove {
	display: block;
	margin: auto;
	width: 40px;
	height: 40px;
	cursor: pointer;
	// margin-right: 10px;
	fill: #c3cad9;
	padding: 8px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border: 2px solid #f5f6f7;
	border-radius: 30px;
	opacity: 1;
}

.planHeader {
	display: flex;
	justify-content: space-between;
	padding: 13px 40px;
	width: 100%;
	background: #fafbfc 0% 0% no-repeat padding-box;
	font-size: 14px;
	font-weight: bold;
	color: #6b7a99;
}

.HeaderPlanText {
	display: flex;
	gap: 10px;
	align-items: center;
}

.settingsIconText {
	width: 40px;
	height: 40px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 12px;
	margin-left: 0px;
	padding: 10px;
	fill: #b6bdc4;
	cursor: pointer;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
}

.btnCenter {
	display: flex !important;
	justify-content: space-around !important;
}

.planBody {
	width: 100%;
	height: 100%;
	padding: 25px;
}

.planForm {
	display: flex;
	justify-content: space-between;
	padding: 13px 40px;
	width: 100%;
	background: #fafbfc 0% 0% no-repeat padding-box;
	font-size: 14px;
	font-weight: bold;
	color: #6b7a99;
	flex-direction: column;
}

.gridInvitFormInfo {
	background-color: #fafbfc;
	min-height: 170px;
	align-items: center;
	width: 100%;
	padding-bottom: 15px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 1100px) {
	.gridInvitFormInfo {
		grid-template-columns: repeat(2, 1fr);
	}
}

.headerPlanTitle {
	width: 100%;
	padding: 15px;
	text-align: left;
	letter-spacing: 0px;
	color: #7d8fb3;
	opacity: 1;
	font-size: 13px;
	font-weight: bold;
}

.headerRiegth {
	display: flex;
	gap: 20px;
	align-items: center;
	letter-spacing: 0px;
	color: #7d8fb3;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.selectRoleInput {
	width: 210px;
	height: 50px;
	margin-top: 2px;
	margin-left: 13px;
	&,
	* {
		height: 100%;
		border-radius: 0px !important;
		input {
			padding: 16.5px;
			font-size: 12px;
			font-weight: bold;
		}
	}
}

.loaderCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.uploadUser {
	display: flex;
	align-items: center;
	justify-content: center;
}

.cardNotification {
	background-color: #fff;
}

.infoNotification {
	background-color: #fff !important;
	text-align: left;
}

.profileBody {
	padding-bottom: 0px;
}

.profileBodyNotification {
	padding-top: 0px;
	padding-bottom: 0px;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	gap: 6%;
}

.centerNotificationHeader {
	font-size: 16px;
	font-weight: bold;
	color: #6b7a99;
}

.containerProfile {
	overflow-y: auto;
	max-height: 650px;
	@include customScroll;
	padding-bottom: 20px;
}

.containerProfileActivity {
	height: 100%;
}

.containerProfileNotif {
	height: auto;
}

.checkboxDiv {
	display: flex;
	gap: 4px;
	align-items: center;
	margin-bottom: 5px;
}

.checkboxNotifLabel {
	font-size: 12px;
	font-weight: bold;
	color: #4d5e80;
}

.centerNotificationbody {
	display: flex;
	flex-direction: column;
	gap: 6px;
	padding-top: 15px;
}

.customInput {
	position: relative;
	width: 210px;
}

.label {
	margin-bottom: 5px;
}

.dropdown {
	position: relative;
	width: 100%;
}

.dropdownHeader {
	background-color: #fff;
	border: none;
	padding: 8px;
	cursor: pointer;
	color: #7d8fb3;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dropdownIcon {
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #555;
	margin-left: 5px;
}

.dropdownContent {
	display: block;
	position: absolute;
	background-color: #fff;
	border: none;
	border-top: none;
	width: 100%;
	max-height: 150px;
	overflow-y: auto;
	z-index: 1;
	@include customScroll;
}

.dropdownItem {
	padding: 2px;
	display: flex;
	align-items: center;
	width: 100%;
}

.checkbox {
	margin-right: 5px;
}

.checkboxLabel {
	cursor: pointer;
	font-size: 10px !important;
	font-weight: bold;
}

.dropdownContentHide {
	display: none;
}

.frameworksLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
