@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.notifications {
	position: fixed;
	z-index: 2000;
	bottom: 50px;
	left: 50%;
	transform: translate(-50%, -50%);

	// @media (max-width: 768px) {
	//     left: 15px;
	//     right: 15px;
	//     bottom: 15px;
	// }
}

.notificationsItem {
	width: auto;
	padding: 7px 15px;
	border-radius: 10px;
	color: $white;
	display: flex;
	align-items: center;
	animation: showItem 0.5s forwards;
	font-weight: 400;
	font-size: 14px;

	& + & {
		margin-top: 10px;
	}

	@keyframes showItem {
		0% {
			opacity: 0;
			transform: translateY(10px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@media (max-width: 768px) {
		width: 100%;
	}
}

.notificationsItemIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: $white;
	margin-right: 15px;
	flex-shrink: 0;

	svg {
		width: 25px;
		height: 25px;
	}

	img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.notificationsIconError {
		width: 20px;
		height: 20px;
	}
}

.notificationsItemLoading {
	background-color: $secondary;
	color: black;
}

.notificationsItemGreen {
	background-color: green;
}

.notificationsItemRed {
	background-color: $errorNotifBG;

	svg {
		fill: $errorNotifBG;
	}
}

.notificationsClose {
	display: none;

	@media (max-width: 768px) {
		display: block;
		padding: 10px 20px;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-left: auto;
	}
}
