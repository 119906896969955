.commentContainer {
	width: 100%;
	display: grid;
	grid-template-columns: auto 150px;
	gap: 12px;
}

.mentionContainer {
	width: 100%;
}

.mention {
	color: #4a85bb;
	text-decoration: none;
}

.mentionSuggestions {
	border-top: 1px solid #eee;
	background: #fff;
	border-radius: 2px;
	cursor: pointer;
	padding-top: 8px;
	padding-bottom: 8px;
	display: grid;
	box-sizing: border-box;
	transform-origin: 50% 0%;
	transform: scaleY(0);
	margin: -16px;
	width: 100%;
}

.mentionSuggestionsEntryContainer {
	display: flex;
	gap: 2px;
	width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
	display: flex;
	flex-direction: column;
}

.mentionSuggestionsEntryContainerRight {
	width: 100%;
	padding-left: 8px;
}

.mentionSuggestionsEntry {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 7px 10px 3px 10px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	border-left: 2px solid transparent;
}

.mentionSuggestionsEntry:active {
	background-color: #cce7ff;
	border-left: 1px solid #3361ff;
}

.mentionSuggestionsEntryFocused {
	composes: mentionSuggestionsEntry;
	background-color: #e6f3ff;
	border-left: 2px solid #3361ff;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {
	font-size: 12px !important;
	font-weight: bold !important;
	color: #7d8fb3;
}

.mentionSuggestionsEntryTitle {
	font-size: 12px !important;
	font-weight: bold !important;
	color: #cce7ff;
}

.mentionSuggestionsEntryAvatar {
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.mentionSuggestionsEntryCont {
	position: absolute;
	height: 150px !important;
	display: flex;
	width: 100%;
}

.mentionSuggestionsEntryLevel {
	overflow: auto;
	width: 100%;
	display: grid;
}

.submitBtn {
	background: #3361ff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	width: 150px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}
