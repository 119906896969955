@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.gpt3Form {
	width: 100%;
	padding: 20px;
}

.inputStyle {
	width: 100%;
	height: 100px;
	font-size: 14px;
	font-weight: bold;
	padding: 10px;
	box-sizing: border-box;
	border: 2px solid #edeff2;
	border-radius: 5px;
}

.buttonStyle {
	background-color: green;
	color: white;
	font-size: 12px;
	font-weight: bold;
	padding: 10px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 33px;
}

.listBtnSubmit {
	display: flex;
	gap: 20px;
	align-items: center;
	margin-top: 5px;
}

.chat {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	position: relative;
}

.chatHistory {
	display: flex;
	flex-direction: column;
	@include customScroll;
	padding: 15px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 100px;
	overflow: auto;
}

.chatMessage {
	display: inline-block;
	border-radius: 8px;
	max-width: 100;
	word-wrap: break-word;
}

.botMessage {
	background-color: #e8f5e9;
	align-self: flex-start;
}

.chatInput {
	flex-grow: 1;
	border: none;
	padding: 0;
	font-size: 14px;
	resize: none;
	height: 24px;
	background: transparent;
	
	&:focus {
		outline: none;
	}
}

.chatButton {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.formInput {
	display: flex;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #E6E8EC;
	padding: 12px 15px;
	align-items: center;
	gap: 10px;
}

.inputSend {
	width: 30px;
	height: 30px;
	fill: #3361ff;
}

.userQuestion {
	align-self: flex-end;
	background-color: #e3f2fd;
}

.messageUser {
	display: flex;
	padding: 20px;
	gap: 10%;
	justify-content: space-between;
}

.userIcon {
	width: 50px !important;
	height: 50px !important;
}

.textName {
	display: flex;
	flex-direction: column;
	gap: 14px;
	width: 80%;
}
.text {
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	white-space: break-spaces;
	font-family: inherit;
}

.userName {
	text-align: left;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.userIconDive {
	display: flex;
	justify-content: center;
	width: 10%;
}

.dotsLoader {
	display: flex;
	justify-content: left;
	align-items: center;
	width: 80%;
}

.dot {
	width: 10px;
	height: 10px;
	margin: 2px;
	border-radius: 50%;
	background-color: #6b7a99;
	animation: load 1s infinite;
}

.dot2 {
	animation-delay: 0.2s;
}

.dot3 {
	animation-delay: 0.4s;
}

@keyframes load {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.relativDiv {
	position: relative;
}

.toResourceBtn {
	width: max-content !important;
	height: max-content !important;
	font-size: 10px !important;
	font-weight: bold !important;
	background-color: #3361ff !important;
	color: #fff !important;
	padding: 5px !important;
	border-radius: 4px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	transition: background-color 0.2s ease !important;

	&:hover {
		background-color: darken(#3361ff, 5%) !important;
	}
}

.chatLogo {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 12px;
	font-weight: bold;
}

.suggestions {
	padding: 20px;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 8px;
	margin-bottom: 20px;

	.suggestionsTitle {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 12px;
		color: #fff;
	}

	.suggestion {
		padding: 10px 15px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 6px;
		margin-bottom: 8px;
		cursor: pointer;
		transition: background 0.2s ease;
		color: #fff;

		&:hover {
			background: rgba(255, 255, 255, 0.15);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.assistantSelector {
	display: flex;
	gap: 10px;
	padding: 15px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);

	.assistantOption {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px 12px;
		border-radius: 6px;
		cursor: pointer;
		transition: background 0.2s ease;
		color: #fff;

		&:hover {
			background: rgba(255, 255, 255, 0.1);
		}

		&.activeAssistant {
			background: rgba(255, 255, 255, 0.15);
		}

		.assistantIcon {
			width: 20px;
			height: 20px;
		}
	}
}

.inputContainer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 15px;
	background: #fff;
	border-top: 1px solid #E6E8EC;
}

.promptSuggestions {
	display: flex;
	gap: 8px;
	margin-bottom: 12px;
	flex-wrap: wrap;
	padding: 0 5px;

	.promptSuggestion {
		padding: 6px 12px;
		background: #fff;
		border-radius: 20px;
		font-size: 13px;
		color: #6B7A99;
		cursor: pointer;
		transition: all 0.2s ease;
		border: 1px solid #E6E8EC;
		white-space: nowrap;

		&:hover {
			background: #F8F9FA;
			border-color: #3361ff;
			color: #3361ff;
		}

		&.selectedSuggestion {
			background: #3361ff;
			color: #fff;
			border-color: #3361ff;
		}
	}
}

.policyForm {
	background: #fff;
	padding: 24px;

	.formHeader {
		text-align: center;
		margin-bottom: 24px;

		h2 {
			font-size: 24px;
			font-weight: 600;
			color: #4d5e80;
			margin-bottom: 10px;
		}

		.formDescription {
			color: #7D8FB3;
			font-size: 14px;
			line-height: 1.5;
		}
	}

	.formContent {
		display: flex;
		gap: 20px;
		margin-bottom: 24px;
		background: #fafbfc;
		padding: 20px;
		border-radius: 10px;

		.formColumn {
			flex: 1;
		}
	}

	.formGroup {
		margin-bottom: 20px;
		position: relative;

		label {
			display: flex;
			align-items: center;
			gap: 6px;
			color: #7D8FB3;
			font-size: 12px;
			font-weight: bold;
			margin-bottom: 8px;

			.required {
				color: #FF3B3B;
			}

			.infoIcon {
				width: 14px;
				height: 14px;
				fill: #ADB8CC;
				cursor: help;
			}
		}

		.tooltip {
			position: absolute;
			top: 100%;
			left: 0;
			background: #fff;
			border: 1px solid #E6E8EC;
			border-radius: 6px;
			padding: 8px 12px;
			font-size: 12px;
			color: #6B7A99;
			box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
			z-index: 10;
			width: 250px;
			margin-top: 4px;
		}

		:global(.input) {
			width: 100%;
			height: 40px;
			background: #FFFFFF;
			box-shadow: 0px 2px 5px #26334D08;
			border-radius: 10px;
			border: 1px solid #E6E8EC;
			padding: 0 15px;
			font-size: 13px;
			color: #4D5E80;
			transition: all 0.2s ease;

			&::placeholder {
				color: #ADB8CC;
			}

			&:focus {
				border-color: #3361ff;
				box-shadow: 0 0 0 2px rgba(51, 97, 255, 0.1);
			}
		}

		.inputError {
			border-color: #FF3B3B !important;
			background: #FFF5F5;
		}

		.errorMessage {
			color: #FF3B3B;
			font-size: 12px;
			margin-top: 4px;
		}

		textarea {
			min-height: 120px !important;
			height: auto !important;
			padding: 12px 15px !important;
			resize: vertical;
		}
	}

	.formFooter {
		border-top: 1px solid #E6E8EC;
		padding-top: 20px;

		.footerNote {
			color: #7D8FB3;
			font-size: 12px;
			margin-bottom: 20px;
			text-align: center;
		}

		.submitBtn {
			width: 100%;
			height: 45px;
			background: #3361ff;
			color: #fff;
			border-radius: 8px;
			font-size: 14px;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.2s ease;

			&:hover:not(:disabled) {
				background: darken(#3361ff, 5%);
			}

			&:disabled {
				opacity: 0.7;
				cursor: not-allowed;
			}
		}
	}
}

.popupContent {
	width: 700px;
	max-width: 90vw;
	background: #fff;
	border-radius: 10px;
}

:global(.popup) {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.policiesList {
	margin: 10px 0;
	padding: 10px;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.03);
}

.policyItem {
	padding: 8px 12px;
	margin: 4px 0;
	border-radius: 6px;
	cursor: pointer;
	transition: background-color 0.2s ease;
	
	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
		color: #007bff;
	}
}

.policyDocument {
	background: #FFFFFF;
	border: 1px solid #E6E8EC;
	border-radius: 10px;
	overflow: hidden;
	margin-top: 10px;
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
}

.policyDocumentHeader {
	display: flex;
	align-items: center;
	padding: 15px 20px;
	border-bottom: 1px solid #E6E8EC;
	background: #FAFBFC;
	gap: 12px;

	.docIcon {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(51, 97, 255, 0.1);
		border-radius: 6px;
		padding: 4px;

		.docIconSvg {
			width: 16px;
			height: 16px;
			fill: #3361FF;
		}
	}

	.docTitle {
		flex: 1;
		font-size: 16px;
		font-weight: 600;
		color: #4D5E80;
	}

	.headerButtons {
		display: flex;
		gap: 8px;
	}
}

.policyDocumentContent {
	padding: 20px;
	background: #fff;

	.policyText {
		font-family: inherit;
		font-size: 14px;
		line-height: 1.6;
		color: #4D5E80;

		h1, h2, h3, h4, h5, h6 {
			margin-top: 1.5em;
			margin-bottom: 0.5em;
			font-weight: 600;
			line-height: 1.3;
			color: #2c3e50;
		}

		h1 {
			font-size: 2em;
			border-bottom: 1px solid #eaecef;
			padding-bottom: 0.3em;
		}

		h2 {
			font-size: 1.5em;
			border-bottom: 1px solid #eaecef;
			padding-bottom: 0.3em;
		}

		h3 {
			font-size: 1.25em;
		}

		p {
			margin: 1em 0;
		}

		ul {
			list-style-type: disc;
			margin: 1em 0;
			padding-left: 2em;
		}

		ol {
			list-style-type: decimal;
			margin: 1em 0;
			padding-left: 2em;
		}

		li {
			margin: 0.5em 0;
			display: list-item;
		}

		// Nested lists
		ul ul {
			list-style-type: circle;
		}

		ul ul ul {
			list-style-type: square;
		}

		ol ol {
			list-style-type: lower-alpha;
		}

		ol ol ol {
			list-style-type: lower-roman;
		}

		table {
			border-collapse: collapse;
			width: 100%;
			margin: 1em 0;
		}

		th, td {
			border: 1px solid #eaecef;
			padding: 0.6em 1em;
			text-align: left;
		}

		th {
			background-color: #f6f8fa;
			font-weight: 600;
		}

		code {
			background-color: #f6f8fa;
			padding: 0.2em 0.4em;
			border-radius: 3px;
			font-family: monospace;
			font-size: 0.9em;
		}

		blockquote {
			margin: 1em 0;
			padding: 0 1em;
			color: #6a737d;
			border-left: 0.25em solid #dfe2e5;
		}
	}
}
