@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.headerCard {
  display: grid;
  grid-auto-columns: auto;
  grid-gap: 20px;
  padding: 10px;
}

.item1 {
  grid-area: 1 / 1 / 2 / 2;
}
.item2 {
  grid-area: 1 / 2 / 2 / 3;
}
.item3 {
  grid-area: 1 / 3 / 2 / 4;
}
.item4 {
  grid-area: 1 / 4 / 2 / 4;
}

.dashboard {
  padding: 21px 20px 31px 20px;
}

.bodyContent {
  display: flex;
  margin-top: 50px;
}

.riegthContent {
  display: flex;
  width: 70%;
  flex-direction: column;
}

.cards {
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 20px;
  padding: 10px;
  width: fit-content;
}

.card {
  margin-top: 50px;
  width: 98%;
  background-color: #fff;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #26334d08;
  border-radius: 10px;
  opacity: 1;
  padding: 5px;
  border: 2px solid #f2f3f5;
}

.noBorder {
  border: none !important;
}

.policiesTitles {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0px;
}

.created {
  padding: 15px;
  color: $blue;
  font-size: 13px;
  font-weight: bold;
}

.needed {
  padding: 15px;
  color: #e02020;
  font-size: 13px;
  font-weight: bold;
}

.progressLin {
  height: 6px;
  border-radius: 30px 0px 0px 30px;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.moreSvg {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  cursor: pointer;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 2px solid #f2f3f5;
  font-weight: bold;
  font-size: 15px;
  color: #4d5e80;
}

.cardBody {
  padding: 10px;
  text-align: center;
  width: 100%;
}

.chart {
  padding: 5px 50px;
  width: 80%;
  margin: auto;
}

.leftContent {
  display: grid;
  grid-auto-columns: auto;
  grid-gap: 10px;
  padding: 8px;
  height: fit-content;
  width: 30%;
}

.chartPadding {
  padding: 40px !important;
}
.equelHight {
  position: relative;
}
.equelHight div:nth-child(2) {
  div:nth-child(1) {
    position: absolute;
    top: 75px;
  }
}

@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.list {
  width: 100%;
  // background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 60px 120px #26334d08;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  float: right;
  padding: 0px 5px;
  margin-bottom: 50px;
  border: 2px solid #f2f3f5;
}

.item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 15px;
  align-items: center;
}

.userSvg {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
}

.userImgSvg {
  width: 25px;
  height: 25px;
  min-width: 35px;
  min-height: 35px;
  fill: #ccc;
  margin-right: 5px;
}

.footerList {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 15px 0px;
  cursor: pointer;
}

.userInfo {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  cursor: pointer;
  background-color: #fff;
  padding: 20px;
}

.headerText {
  font-weight: bold;
  font-size: 14px;
}

.userInfoName {
  font-size: 12px;
  letter-spacing: 0px;
  color: #6b7a99;
  opacity: 1;
  margin-right: 5px;
}

.userInfoRole {
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #adb8cc;
  opacity: 1;
}

.loaderStyles {
  margin: 0 auto;
  padding: 50px 0px;
  font-size: 14px;
  font-weight: bold;
  color: #7d8fb3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventsTitle {
  text-align: left;
  letter-spacing: 0px;
  color: #adb8cc;
  opacity: 1;
  font-size: 10px;
  font-weight: bold;
}

.eventsText {
  text-align: left;
  letter-spacing: 0px;
  color: #4d5e80;
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.eventsDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.homeProfile {
  min-width: 35px !important;
  min-height: 35px !important;
  max-width: 35px !important;
  max-width: 35px !important;
}

.containerListUser {
  overflow: auto;
  @include customScroll;
}

.loaderCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.eventLabel {
  padding: 2px;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
}

.actionsContent {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: $shadow;
  position: absolute;
  top: 100%;
  z-index: 3;
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 768px) {
    min-width: unset;
    width: 100%;
    position: static;
    border-radius: 0 0 7px 7px;
    margin-top: 10px;
  }
}

.dropResourcActionsContent {
  border-radius: 8px;
}

.resourcesHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
  width: 100%;
}

.dropResourceBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7d8fb3;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #3361ff;
    .resourcesIcon {
      fill: #3361ff;
    }
  }
}

.sortByDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  color: #7d8fb3;
  font-size: 10px;
  font-weight: bold;
  width: 100%;
  height: 25px;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  svg {
    fill: #7d8fb3;
    transform: rotate(90deg);
    width: 10px;
    height: 10px;
  }
}

.FilterByDate {
  width: 60px !important;
  font-size: 12px;
}

.loaderStyles {
  margin: 0 auto;
  padding: 50px 0px;
  font-size: 14px;
  font-weight: bold;
  color: #7d8fb3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.headerList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerWithFilter {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f2f3f5;
}

.listOfFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 15px 15px 15px;
}

.checkStyles {
  width: 10px;
  height: 10px;
  fill: #7d8fb3;
}

.iconMargin {
  margin-top: 3px;
}

.calendarWidget {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}

.eventsTable {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0 10px;
  padding: 20px;
}

.eventsTable th,
.eventsTable td {
  text-align: left;
}

.eventsTable th {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  font-weight: bold;
}

.eventsTable td {
  padding: 20px;
}

.eventsTable th:nth-child(1),
.eventsTable td:nth-child(1) {
  width: 10%;
}

.eventsTable td:nth-child(1) {
  border-right: 1px solid #f2f3f5;
  text-align: center;
}

.eventsTable th:nth-child(2),
.eventsTable td:nth-child(2) {
  width: 15%;
}

.eventsTable th:nth-child(3),
.eventsTable td:nth-child(3) {
  width: 45%;
}

.eventsTable th:nth-child(4),
.eventsTable td:nth-child(4) {
  width: 15%;
}

.eventsTable th:nth-child(5),
.eventsTable td:nth-child(5) {
  width: 15%;
}

.tbodyRow {
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.imageUsers {
  width: 50px;
  height: 50px;
}

.eventsTable td:first-child {
  text-align: center;
  vertical-align: middle;
  
  > div {  // This will target both Avatar and ProfileImage components
    margin: 0 auto;  // Centers horizontally
    display: flex;  // Changed from inline-block to flex
    align-items: center;  // Centers content vertically
    justify-content: center;  // Centers content horizontally
    width: 35px;  // Match the size of Avatar
    height: 35px;  // Match the size of Avatar
  }
}
