.controlsContainer {
	font-family:
		Gotham Book,
		sans-serif;
	border: 2px solid #edeff2;
	width: 100%;
	overflow: hidden;
	border-radius: 15px;
	margin-bottom: 0;
	min-width: 700px;
}

.DotsLoader {
	width: 100%;
	height: 100%;
	min-height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
}
