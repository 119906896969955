@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.main {
	display: flex;
	height: 100%;
	flex-direction: column;
	width: 100%;
	padding: 30px;
	.container {
		flex-grow: 1;
	}
}

.container {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 10px 30px #26334d08;
	border-radius: 20px;
	opacity: 1;
	width: 100%;
}

.title {
	text-align: left;
	font-size: 18px;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	font-weight: bold;
	margin: 35px 0;
}

.editor {
	margin: 20px 0px;
	background: #fff;
	border-radius: 12px;
	padding: 35px 35px 10px 35px;
}

.dateTime {
	font-size: 16px;
	color: $blue;
	margin: 10px 0px;
}

.titlesOfText {
	font-weight: bold;
	font-size: 24px;
	margin-top: 50px;
}

.inputReport {
	background-color: #fff;
	padding: 8px 15px;
	width: 206px;
	border-radius: 20px;
	border: 1px solid #f1f1f5;
	margin-top: 20px;
}

.datePicker {
	z-index: 5;
}

.dateReport {
	padding: 5px 5px;
	width: 206px;
	color: $blue;
	font-size: 18px;
}

.listInputs {
	display: flex;
	flex-wrap: wrap;
	.inputReport {
		margin-right: 50px;
	}
	.titlesOfText {
		margin-left: 5px;
	}
	.dateReport {
		margin-right: 50px;
	}
}

.submitbtnReport {
	display: flex;
	justify-content: right;
}

.submitbtn {
	width: 150px;
	margin-top: 100px;
	background-color: $blue;
	color: #fff;
	font-size: 12px;
}

.reportText {
	color: $blue;
}

.cardReport {
	margin: 1px;
	padding: 50px;
	background-color: #fff;
	border-radius: 20px;
}

.textContent {
	color: $blue;
	border-radius: 0px 12px;
}

.pageHeader {
	text-align: left;
	font-size: 18px;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	font-weight: bold;
	margin-bottom: 66px;
}

.HeaderCard {
	display: flex;
	justify-content: center;
	padding: 25px;
	padding-top: 0px;
	font-size: 16px;
	flex-direction: column;

	.blue {
		color: $blue;
		font-size: 18px;
	}
	div {
		text-align: center;
		padding: 10px;
		font-weight: bold;
	}
}

.labelInput {
	font-size: 16px !important;
}

.reportDetails {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding: 50px;
	margin-bottom: 50px;
}

.commentsReport {
	font-size: 18px;
	font-weight: bold;
}

.textHeaderLeftText {
	text-align: left;
	letter-spacing: 0px;
	color: #7d8fb3;
	opacity: 1;
	font-size: 14px;
	font-weight: bold;
}

.textHeaderLeft {
	display: flex;
	gap: 30px;
	align-items: center;
	justify-content: center;
}

.textHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fafbfc 0% 0% no-repeat padding-box;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	height: 100px;
	padding: 0pc 30px;
	border-bottom: 1px solid #f5f6f7;
}

.textHeaderLeftIcon {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	width: 40px;
	height: 40px;
	padding: 10px;
	fill: #c3cad9;
	cursor: pointer;
}

.textHeaderReigthText {
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.teamsPopupBtn {
	width: 112px;
	height: 40px;
	font-weight: bold;
	border-radius: 30px !important;
	background: #ffffff 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 30px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-items: center;
	gap: 10px;
	@include defaultBtn;
	&:hover {
		background-color: #fff !important;
	}
	svg {
		fill: #c3cad9 !important;
		opacity: 1;
		width: 15px;
		height: 15px;
	}
}

.btnBlue {
	width: 112px;
	height: 40px;
	font-weight: bold;
	border-radius: 30px !important;
	color: #ffffff !important;
	background: #3361ff 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 30px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-items: center;
	gap: 10px;
	@include defaultBtn;
	&:hover {
		background-color: #3361ff !important;
	}
	svg {
		fill: #ffffff80 !important;
		opacity: 1;
		width: 15px;
		height: 15px;
	}

	.textHeadersave {
		color: #fff;
		font-size: 12px;
		font-weight: bold;
	}
}

.textHeaderRiegth {
	display: flex;
	gap: 20px;
}

.reportFormBodyLeft {
	width: 70%;
	border-right: 2px solid #f5f6f7;
	height: 100%;
	padding: 50px;
}

.reportFormBodyRiegth {
	width: 30%;
	height: 100%;
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.reportFormBody {
	width: 100%;
	height: 100%;
	display: flex;
}

.titleReport {
	display: flex;
	width: 100%;
	height: 90px;
	border: 2px solid #f5f6f7;
	align-items: center;
	padding: 20px;
}

.inputStyle {
	width: 100%;
	margin: 0px !important;
}

.titleReportLeft {
	width: 20%;
	min-width: 135px;
	height: 100%;
	border-right: 2px solid #f5f6f7;
	font-size: 18px;
	font-weight: bold;
	color: #4d5e80;
	display: flex;
	justify-content: center;
	align-items: center;
}

.titleReportReight {
	width: 80%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&,
	input {
		text-align: left;
		letter-spacing: 0px;
		color: #4d5e80;
		opacity: 1;
		font-size: 18px;
		font-weight: bold;
	}
}

.controlCardHeaderIconPopup {
	height: 25px;
	width: 25px;
}
.controlCardHeaderLeftPopup {
	display: flex;
	align-items: center;
	gap: 20px;
}

.controlCardHeaderPopup {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* UI Properties */
	background: #fafbfc 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	opacity: 1;
	padding: 10px 20px;
	height: 65px;
	width: 100%;
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	border: 1px solid #edeff2;
}

.controlCardPopup {
	width: 100%;
	border: 1px solid #edeff2;
	background-color: #fff;
	margin: 0 auto;
	margin-top: 20px;
	border-radius: 10px;
}
.controlCardBodyPopup {
	background-color: #fff;
}

.closeIconPopup {
	width: 30px;
	height: 30px;
	padding: 8px;
	fill: #c3cad9;
	background-color: #fff;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0px 10px 30px #3361ff26;
}

.controlPopupHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fafbfc 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	opacity: 1;
	height: 9%;
	font-size: 12px;
	font-weight: bold;
	color: #4d5e80;
	padding: 0px 20px;
}

.controlCardBodyPopup {
	margin: 5px;
	// max-height: 100px;
	// height: 100px;
	// overflow: auto;
	// @include customScroll;
	display: flex;
	align-items: center;
}

.borderRed {
	border-left: 3px solid red;
}

.borderBlue {
	border-left: 3px solid blue;
}

.borderGreen {
	border-left: 3px solid green;
}

// .controlCardBodyPopup{
//     font-size: 12px;
//     font-weight: bold;
//     color: #7D8FB3;
// }

.textPopup {
	padding: 10px;
}

.controlPopupBody {
	height: 90%;
	@include customScroll;
}

.arrowInverst {
	width: 30px;
	height: 30px;
	padding: 9px;
	fill: #c3cad9;
	background-color: #fff;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid #edeff2;
	transform: rotate(90deg);
}

.arrowStyle {
	width: 15px;
	height: 15px;
	fill: #dadee6;
	transform: rotate(90deg);
}

.arrowDateStyle {
	width: 15px;
	height: 15px;
	fill: #dadee6;
}
.titleriegthSelect {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	letter-spacing: 0px;
	color: #6b7a99;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}
.selectClassName {
	width: 100%;
	font-size: 12px;
	font-weight: bold;
	& > div {
		background-color: #fff;
		box-shadow: none;
		border: 2px solid #f5f6f7;
	}
	&,
	svg {
		fill: #dadee6 !important;
	}
}

.ReportDate {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	color: #7d8fb3;
	font-weight: bold;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: none;
	border: 2px solid #f5f6f7;
	border-radius: 5px;
	opacity: 1;
	padding: 10px 15px;
}

.startEndDate {
	text-align: left;
	letter-spacing: 0px;
	color: #7d8fb3;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
}

.startEndTime {
	text-align: left;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	margin: 5px 0px;
}

.inputContainer {
	border: none;
	&,
	input {
		text-align: left;
		letter-spacing: 0px;
		color: #909cb4;
		opacity: 1;
		font-size: 14px;
		font-weight: bold;
		// background: #FFFFFF 0% 0% no-repeat padding-box;
		// box-shadow: 0px 2px 5px #26334D0D;
		// border: 2px solid #F5F6F7;
		border-radius: 5px;
		opacity: 1;
	}
	input {
		padding: 10px 15px;
	}
}

.noBox {
	box-shadow: none;
}

.datePickerReport {
	width: 40%;
}

.datePickerReportSvg {
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.table {
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	.tableHeader {
		display: flex;
		div {
			letter-spacing: 0px;
			color: #7d8fb3;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
	}

	.row {
		display: flex;
		align-items: center;
		margin: 15px 0px;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 5px #26334d08;
		border-radius: 5px;
		opacity: 1;
		cursor: pointer;
		div {
			letter-spacing: 0px;
			color: #4d5e80;
			opacity: 1;
			font-size: 12px;
			font-weight: bold;
		}
		& > *:first-child {
			border-right: 2px solid #f5f6f7;
			padding: 20px 22px;
		}
	}
}

.imageProfil {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	color: #fff !important;
	font-size: 13px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	letter-spacing: 0.5px !important;
}

.risk {
	min-width: 100px;
	/* padding: 0px 62px; */
	text-align: -webkit-center;
}

.statusComp {
	width: 100%;
	height: 5px;
	display: flex;
	border-radius: 5px;
	background-color: #999;
	border-radius: 20px;
}

.riskComp {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0px 2px 5px #26334d08;
	border: 2px solid #f5f6f7;
	opacity: 1;
}

.moreSvg {
	width: 40px;
	height: 40px;
	padding: 10px;
	fill: #c3cad9;
	transform: rotate(90deg);
	cursor: pointer;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border: 2px solid #f5f6f7;
	border-radius: 30px;
}

.bodyDataControls {
	display: flex;
	justify-content: center;
	padding: 50px;
	text-align: center;
	margin: auto;
}

.childRow {
	margin-left: 25px !important;
}

.activeFramework {
	color: #3361ff !important;
}

.statusCompDiv {
	width: 100%;
	height: 2px;
	background-color: #3361ff;
}

.childName {
	min-width: 215px;
}

.containerAllReport {
	width: 100%;
}

.ShowReportHeader {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	width: 100%;
	height: 160px;
	display: grid;
	grid-template-columns: auto auto auto;
}

.imageOwner {
	border-right: 2px solid #f5f6f7;
	display: grid;
	grid-template-columns: 45% 55%;
	align-items: center;
	position: relative;
}
.dataOwner {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-size: 12px;
	font-weight: bold;
	color: #7d8fb3;
	border-right: 2px solid #f5f6f7;
	padding: 0px 35px;
	div {
		display: flex;
		align-items: center;
	}
}

.nameOwnerRole {
	font-size: 14px;
	font-weight: bold;
	color: #4d5e80;
}

.roleReporOwner {
	color: #adb8cc;
	font-size: 12px;
	font-weight: bold;
	margin-top: 10px;
}

.imageProfilReportShow {
	width: 80px;
	height: 80px;
	margin: auto;
}

.containerShowReport {
	padding: 0px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.bodyShowReport {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 10px;
	opacity: 1;
	width: 100%;
	height: 100%;
	margin-top: 30px;
	padding: 50px;
}

.bodyHeaderReport {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #4d5e80;
	font-weight: bold;
}

.bodyDate {
	font-size: 12px;
}

.CloudStorage {
	font-size: 14px;
}

.actionsReport {
	margin-top: 100px;
	margin-bottom: 50px;
}

.btnReport {
	background: #f7f8fa 0% 0% no-repeat padding-box;
	border-radius: 30px;
	opacity: 1;
	width: max-content;
	height: 40px;
	color: #7d8fb3;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	margin: 15px 0px;
}

.reportText {
	// font-size: 13px;
	// font-weight: bold;
	color: #6b7a99;
	padding: 0px 15px;
}

.reportTextIncident {
	color: #4d5e80;
}

.nbrReportTitle {
	text-align: left;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 15px;
	font-weight: bold;
}

.nbrReportValue {
	font-size: 12px;
	font-weight: bold;
	color: #6b7a99;
	margin-left: 50px;
	margin-top: 40px;
}

.closePreview {
	width: 20px;
	height: 20px;
	fill: #909cb4;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	background-color: #dadee6;
	border-radius: 50%;
	padding: 5px;
}

.extraDataOwner {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.preview {
	width: 100%;
}

.inputReportForm {
	z-index: -1;
}

.noReportMsg {
	display: flex;
	align-items: center;
	margin: 15px 0px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	justify-content: center;
	height: 100px;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	justify-content: center !important;
}

.newReport {
	display: flex;
	align-items: top;
	justify-content: space-between;
	margin-bottom: 25px;
}

.newReportBtn {
	display: flex;
	align-items: center;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	font-size: 12px;
	font-weight: bold;
	background-color: #3361ff;
	color: #fff;
	height: 40px;
	width: 150px;
	min-width: 150px;
	justify-content: space-around;
	border-radius: 4px;
	svg {
		fill: #fff;
		width: 15px;
		height: 15px;
	}
}

.listIncidentUsers {
	position: relative;
}

.titleBorder {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: none;
	border: 2px solid #f5f6f7;
	border-radius: 5px;
	opacity: 1;
	padding: 10px 15px;
	cursor: pointer;
}

.listIconUserReport {
	margin: 0;
	width: 10px;
	height: 10px;
}

.listReport {
	top: 37px;
	right: 0px;
	left: 0px;
}

.listProfiles {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	overflow: auto;
	@include customScroll;
	gap: 5px;
}

.datRiskBtn {
	background: #3361ff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 5px;
	opacity: 1;
	display: flex;
	color: #fff;
	font-size: 10px;
	font-weight: bold;
	align-items: center;
	justify-content: space-between;
	width: 150px;
	height: 30px;
}

.calendarIcon {
	width: 20px;
	height: 20px;
	fill: #fff;
}

.eventItem {
	margin: 3px 0px;
	width: 50%;
	height: 30px;
	min-height: 30px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d0d;
	border-radius: 2px;
	opacity: 1;
	font-size: 10px;
	font-weight: bold;
	text-align: left;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 5px;
}

.eventItemFull {
	margin: 10px 0px;
	width: 100%;
	height: 30px;
	min-height: 30px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d0d;
	border-radius: 2px;
	opacity: 1;
	font-size: 10px;
	font-weight: bold;
	text-align: left;
	letter-spacing: 0px;
	color: #4d5e80;
	opacity: 1;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 5px;
}

.mainLoader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
}

.rightSideForm {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.inputReportForm {
	margin: 0px;
	width: 100%;
	justify-content: space-between;
	color: #7d8fb3;
	background: transparent;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: none;
	border: 2px solid #f5f6f7;
	border-radius: 5px;
	opacity: 1;
	input {
		font-size: 12px;
		&::placeholder {
			font-size: 12px;
		}
	}
}

.tagsNumber {
	padding-right: 15px;
}

.maxTagNumber {
	color: red;
}

.responsiveTable {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	li {
		width: 100%;
		height: 90px;
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		font-weight: bold;
		color: #7d8fb3;
		&:first-child {
			height: max-content;
		}
	}
	.tableHeader {
		background-color: transparent;
		color: #7d8fb3;
		text-transform: uppercase;
		letter-spacing: 0.03em;
		font-size: 12px;
		font-weight: bold;
	}
	.tableRow {
		background-color: #ffffff;
		box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
	}
	.col1 {
		flex-basis: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	.col2 {
		flex-basis: 10%;
		padding-left: 15px;
	}
	.col3 {
		flex-basis: 40%;
	}
	.col4 {
		flex-basis: 25%;
	}
	.col5 {
		flex-basis: 10%;
	}
	.col6 {
		flex-basis: 5%;
	}

	.chip {
		--primary: #fff;
		--secondary: #111;
		padding: 0.25rem 0.5rem;
		box-sizing: border-box;
		background: var(--secondary);
		border-radius: 0.2rem;
		color: var(--primary);
		font-size: 10px;
		padding-right: 1.5rem;
		position: relative;
	}

	.chipContainer {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}

	.col1Border {
		border-right: 2px solid #f5f6f7;
	}

	@media all and (max-width: 767px) {
		.tableHeader {
			display: none;
		}

		li {
			display: block;
		}
		.col {
			flex-basis: 100%;
		}
		.col {
			display: flex;
			padding: 10px 0;
			&:before {
				color: #6c7a89;
				padding-right: 10px;
				content: attr(data-label);
				flex-basis: 50%;
				text-align: right;
			}
		}
	}
}

/* Add styles for the tooltip */
.chipContainer {
	position: relative;
}

.moreChips {
	position: relative !important;
	background-color: transparent !important;
	color: #3361ff !important;
}

.tooltip {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 5px;
	color: #6b7a99;
}

.ShowTooltip {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 5px;
	border-radius: 4px;
}

.moreChips:hover .tooltip {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 5px;
	border-radius: 4px;
}

.filterBtn {
	position: relative;
	background-color: #fff;
	color: #6b7a99;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 200px;
	height: 40px;
	border-radius: 4px;
	padding: 15px;
	cursor: pointer;
	svg {
		width: 12px;
		height: 12px;
		fill: #6b7a99;
	}
}

.filterDropDown {
	position: relative;
}

.tagsDropDown {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: auto;
	background-color: #fff;
	box-shadow: none;
	border: 1px solid #eaeaea;
	border-radius: 5px;
	z-index: 3;

	ul {
		display: flex;
		flex-direction: column;
		gap: 2px;
		max-height: 140px;
		overflow: auto;
		@include customScroll;
		li {
			color: #6b7a99;
			font-weight: bold;
			cursor: pointer;
			font-size: 10px;
			border-top: 1px solid rgba(247, 249, 251, 0.5);
			padding: 8px;
		}
	}
	.tagsSearch {
		padding: 8px;
		input {
			width: 100%;
			padding: 5px;
			border-radius: 4px;
			border: 1px solid #eaeaea;
			font-size: 10px;
			color: #6b7a99;
			font-size: 10px;
			font-weight: bold;
			&::placeholder {
				font-size: 10px;
			}
		}
	}
}

.listOfSelectedTags {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.tagsContainer {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 15px;
}

.closeSvg {
	fill: #fff;
	width: 15px;
	height: 15px;
}

.chipTag {
	--primary: #fff;
	--secondary: #111;
	padding: 0.25rem;
	box-sizing: border-box;
	background: var(--secondary);
	border-radius: 0.2rem;
	color: var(--primary);
	font-size: 10px;
	padding-right: 1.5rem;
	font-weight: bold;
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}

.chipTag > svg {
	border: none;
	background: transparent;
	color: transparent;
	position: absolute;
	top: 50%;
	right: 0.15rem;
	transform: translateY(-50%);
}

.chipTag > svg {
	cursor: pointer;
	fill: var(--primary);
	width: 15px;
	height: 15px;
}

.ownerHeader {
	justify-content: left !important;
	padding-left: 5px;
}
