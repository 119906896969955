//
// Palette
//

$secondary: #f7f9fb;
$black: #000;
$white: #fff;
$blackLight: #2c405a28;
$blue: #1a7dc2;
$redDark: #e02020;

$bg: #1c1c1c;
$blackLighter: #3b3b3b;
$authBG: #ebebeb;
$btnBG: #666;
$divisionBG: #575757;
$btnRegisterBG: #858585;
$forgotPass: #999;
$checkboxBG: #d9d9d9;
$menuBG: #4a4a4a;
$errorNotifBG: #ff0000;
$menuSessionBG: #b0b0b0;
$scrollBarBG: #cacaca;
$membersBG: #f5f5f5;
$adminItemBG: #373737;
$checkboxToggleBG: #757575;

//
// Static sizes
//

$dashboardTopPanelHeight: 60px;
$headerHeight: 70px;
$headerMobileHeight: 60px;

//
// Other styles
//

$shadow: 0 1.5px 5px rgba($black, 0.07);
