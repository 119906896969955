@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/mixins";

.container {
	display: flex;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.form {
	width: 50%;
	height: 100%;
	text-align: center;
	padding-top: 50px;
	background-color: $secondary;
}

.image {
	width: 50%;
	height: 100%;
	background-color: #000;
}

.Header {
	font-size: 40px;
	font-weight: bold;
	padding: 25px 0px;
	width: 400px;
	display: inline-flex;
	text-align: left;
}

.label {
	padding-top: 20px;
	color: #999;
	font-size: 16px;
	width: 400px;
	margin: 0 auto;
	text-align: left;
}

.input {
	border: 1px solid $secondary;
	border-radius: 4px;
	box-shadow: 0 0 9pt 0.2pt #eee;
	background-color: #fff;
	padding: 2px;
	font-size: 16px;
	width: 400px;
	margin-top: 3px;
	display: inline-flex !important;
}

.loginInputs {
	margin-bottom: 20px;
}

.btn {
	width: 400px;
	padding: 20px;
	font-size: 18px;
	margin-top: 10px;
}

.btnSubmit {
	background-color: rgb(62, 62, 255);
	color: #fff;
	font-weight: bold;
	display: flex !important;
	margin: auto !important;
	justify-content: center !important;
}

.btnGoogle {
	background-color: #fff;
	color: #000;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		margin-right: 10px;
	}
}

.signUpLink {
	margin-top: 10px;
	font-size: 16px;
	font-weight: bold;
	width: 100%;
	display: flex;
	justify-content: center;
	a {
		margin-left: 3px;
		&:hover {
			color: $blue;
			text-decoration: underline;
		}
	}
}

.eyeIcon {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 478px;
	left: 518px;
}

.googleIcon {
	width: 25px;
	height: 25px;
}

.submitText {
	margin-right: 10px;
}

.logo {
	width: 300px;
	height: 100px;
	margin: left;
	margin-left: 20px;
	margin-bottom: 20px;
	background-image: url("/assets/images/SecureLabsLogo_Text.png");
	background-size: contain;
	background-repeat: no-repeat;
}

/* For screens smaller than 768px (e.g., mobile devices) */
@media (max-width: 767px) {
	.logo {
		width: 200px;
		height: 66px;
		margin-left: 10px;
		margin-bottom: 10px;
	}
}

/* For screens between 768px and 1024px (e.g., tablets) */
@media (min-width: 768px) and (max-width: 1024px) {
	.logo {
		width: 250px;
		height: 83px;
		margin-left: 15px;
		margin-bottom: 15px;
	}
}

/* For screens larger than 1024px (e.g., desktops) */
@media (min-width: 1025px) {
	.logo {
		width: 300px;
		height: 100px;
		margin-left: 20px;
		margin-bottom: 20px;
	}
}
