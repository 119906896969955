@import "../../../assets/styles/includes/vars";
@import "../../../assets/styles/includes/mixins";

.container {
	text-align: center;
	background-color: transparent;
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #edeff2;
	align-items: center;
	min-width: 1000px;
	padding: 15px 15px 15px 0px;
}

.svgIcons {
	height: 35px;
	width: 45px;
	cursor: pointer;
}

.logo {
	margin-left: 13px;
	display: flex;
	align-items: center;
}
.marginLogo {
	margin-left: 5px !important;
}
.logoText {
	margin-bottom: auto;
	margin-top: auto;
	width: 150px;
	height: 27px;
	img {
		width: 150px;
		height: 27px;
	}
}

.header {
	display: flex;
	width: max-content;
	background: transparent;
	position: relative;
}

.name {
	margin: auto;
	font-size: 13px;
	color: #6b7a99;
	font-weight: bold;
	cursor: pointer;
}

.image {
	width: 32px;
	height: 32px;
	border-radius: 32px;
	margin: auto;
}

.user {
	display: flex;
	flex-direction: column;
	margin-left: 12px;
	margin-right: 12px;
}

.role {
	font-size: 12px;
	font-weight: normal;
	color: $black;
	opacity: 70%;
	text-align: left;
}

.settingsIcon {
	width: 50px;
	height: 50px;
	margin-top: auto;
	margin-bottom: auto;
	background-color: #fff;
	border-radius: 50%;
	padding: 15px;
	fill: #b6bdc4;
	cursor: pointer;
	margin-right: 20px;
}

.userIcon {
	margin-top: auto;
	margin-bottom: auto;
	width: 20px;
	height: 20px;
	background-color: #c3cad9;
	border-radius: 50%;
	padding: 2px;
	fill: #fff;
}

.imageProfile {
	display: inline-grid;
	display: -ms-inline-grid;
	display: -moz-inline-grid;
}

.svgNavigation {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 50px;
	padding: 24px;
	svg {
		width: 15px;
		height: 15px;
	}
	.reversSvg {
		transform: rotate(180deg);
		margin-right: 10px;
	}
}

.svgIconsTop {
	height: 50px;
	width: 50px;
	cursor: pointer;
	margin-right: 15px;
	padding: 10px;
	fill: #adb8cc;
	opacity: 1;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #26334d08;
	border-radius: 50%;
	opacity: 1;
	margin-left: 8px;
}

.logoText {
	margin-top: 14px;
	width: 165px;
	height: 27px;
	img {
		width: 145px;
		height: 27px;
	}
}

.actionsContent {
	min-width: 190px;
	padding: 0 10px;
	border-radius: 20px;
	background-color: #fff;
	box-shadow: $shadow;
	position: absolute;
	top: 79%;
	right: 18px;
	z-index: 3;
	border: 1px solid #eaeaea;

	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.actionsContentBtn {
	display: flex;
	justify-content: space-between;
	padding: 5px 5px;
	font-size: 12px;
	font-weight: 400;
	color: #000;
	cursor: pointer;
	transition: all 0.15s;
	align-items: center;
	font-weight: bold;
	padding: 10px 5px;
	svg {
		width: 20px;
		height: 20px;
		margin-left: 5px;
		flex-shrink: 0;
		fill: #b6bdc4;
		transition: all 0.15s;
		transform: rotate(180deg);
	}
}

.serachContainerDiv {
	position: relative;
	width: 42%;
	height: 50px;
	display: flex;
	align-items: center;
}

.withContainer {
	width: 100% !important;
}

.dropDownSearch {
	min-width: 100%;
	padding: 0 10px;
	border-radius: 20px;
	background-color: #fff;
	box-shadow: 0 1.5px 5px rgba(0, 0, 0, 0.07);
	position: absolute;
	top: 100%;
	z-index: 15;
	border: 1px solid #eaeaea;
}

.dropDownSearchItem {
	font-size: 12px;
	font-weight: bold;
	text-align: left;
	padding: 10px 15px;
	border-bottom: 2px solid #fafbfc;
	cursor: pointer;
	color: #7d8fb3;
}

.searchHeaderItem {
	cursor: initial;
}

.itemSearchContainer {
	max-height: 115px;
	overflow: auto;
	@include customScroll;
}

.loaderSearch {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px;
}

.notification {
	position: relative;
}

.notificationNbr {
	position: absolute;
	background-color: red;
	color: #fff;
	font-size: 10px;
	font-weight: bold;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 35px;
}

.actionsContentNotif {
	min-width: 300px;
	min-height: 150px;
	padding: 0 10px;
	border-radius: 20px;
	background-color: #fff;
	box-shadow: $shadow;
	position: absolute;
	top: 79%;
	right: 50px;
	z-index: 3;
	border: 1px solid #eaeaea;
	overflow: auto;
	height: 300px;
	@include customScroll;

	@media (max-width: 768px) {
		min-width: unset;
		width: 100%;
		position: static;
		border-radius: 0 0 7px 7px;
		margin-top: 10px;
	}
}

.loadNotif {
	width: 100%;
	min-height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
}

.notifHeaderBtn {
	color: #fff !important;
	background-color: #3361ff !important;
	width: 95px !important;
	height: 35px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	font-size: 12px !important;
	font-weight: bold !important;
	border-radius: 25px !important;
}

.notifHeader {
	padding: 10px 5px;
	width: 100%;
	display: flex;
	justify-content: right;
}

.notifMessage {
	width: 100%;
	padding: 10px;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #6b7a99;
	svg {
		width: 20px;
		height: 20px;
		fill: #7d8fb3;
		cursor: pointer;
	}

	div {
		text-align: justify;
		padding-right: 45px;
	}
}

.messageNotif {
	text-align: justify;
	margin-right: 50px;
}
