.search {
	position: relative;
	width: 300px;
	height: 40px;

	input {
		width: 100%;
		height: 100%;
		padding: 0 45px;
		border: 1px solid #edeff2;
		border-radius: 30px;
		font-size: 12px;
		font-weight: bold;
		color: #7d8fb3;
		background: #fff;

		&::placeholder {
			color: #7d8fb3;
		}

		&:focus {
			outline: none;
			border-color: #3361ff;
		}
	}

	button {
		position: absolute;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		padding: 0;
		cursor: pointer;

		svg {
			width: 20px;
			height: 20px;
			fill: #7d8fb3;
		}
	}
}

// Add styles for the large search icon when no results
.bigSearchIcon {
	width: 150px;
	height: 150px;
	fill: #edeff2;
	margin-bottom: 20px;
}

.noResults {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
	color: #7d8fb3;
	font-size: 14px;
	font-weight: bold;
}
